@import "./styles/colors";
@import "./styles/spacings";
@import "./styles/function";
@import "./styles/globalStyles";
@import "./Theme/Theme";

.app-container {
  height: 100vh;
  background: $landingPageBg;
  font-family: $fontFamily-regular;
  scroll-behavior: smooth;
  overflow-x: hidden;

  .ant-layout {
    background: $greyShade;
  }

  .ant-layout-header {
    padding: 0;
    background-color: $white;
    min-height: 7vmin;
    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  }

  .ant-layout-sider {
    z-index: 101; //added to avoid the overlapping of sticky footer
  }

  .ant-layout-content.content {
    overflow: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: rem(5);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rem(5);
      background-color: $text-grey;
    }
  }

  .ant-card.ant-card-bordered {
    border-radius: rem(8);
  }
  .ant-layout-content.content {
    height: calc(100vh - rem(64));
    overflow: hidden auto;
    padding: rem(40);
    padding-top: rem(20);
    &::-webkit-scrollbar {
      width: rem(5);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: rem(5);
      background-color: $text-grey;
    }
  }
}
