@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.dashboard-container {
  .divider {
    background-color: $primary;
    width: rem(100);
  }

  .banner-container {
    border-radius: rem(10);
    background: linear-gradient(
        95deg,
        rgba(172, 218, 251, 0.62) 1.79%,
        rgba(172, 218, 251, 0.12) 32.53%
      ),
      $white;

    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
    justify-content: space-between;

    display: flex;
    padding: 1.5rem;
    align-items: center;

    .banner-image {
      // height: 15.5625rem;
    }

    .banner-title-container {
      width: 60%;
      color: $banner-background;
      gap: rem(24);

      .banner-title {
        color: $banner-background;
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(24);
        font-style: normal;
        font-weight: 600;
        line-height: rem(40);
      }

      .banner-text {
        color: black;
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);
      }
    }
  }

  .view-all-btn {
    color: var(--primary-6, $count-tag-bg);
    text-align: center;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
    cursor: not-allowed;
  }

  .courses-container {
    gap: 2rem 5.12rem;
  }

  .dash-tile-container {
    width: 27%;
    row-gap: 2.5rem;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .dash-tile-container2 {
    width: 100%;
    row-gap: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .list {
    width: 100%;
    gap: rem(48);
  }

  .scroll-btn {
    background: none;
    border: none;
    height: rem(50);
    align-self: center;
    z-index: 5;
  }

  .dash-sub-title {
    color: $sub-title;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(20);
    font-style: normal;
    font-weight: 600;
    line-height: rem(28);
    margin: 0;
  }

  .dash-title {
    color: $sub-title;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(24);
    font-style: normal;
    font-weight: 600;
    line-height: rem(32);
    margin: 0;
    margin-bottom: rem(24);
  }

  .dash-loader {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .view-all-btn {
    color: var(--primary-6, $count-tag-bg);
    text-align: center;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: rem(20);
  }

  .courses-container {
    gap: 2rem 5.12rem;
  }

  .scroll-btn-icon {
    font-size: rem(30);
    color: $primary;
  }

  .dash-sub-title {
    color: $sub-title;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(20);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);
    margin: 0;
  }

  .dash-title {
    color: $sub-title;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(24);
    font-style: normal;
    font-weight: 600;
    line-height: rem(30);
    margin: 0;
    margin-bottom: rem(24);
  }

  .dash-loader {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .dash-section-header {
    margin-top: 2rem;
  }

  .dcontainer {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: rem(1440)) {
  .banner-image {
    // height: 13.5625rem;
  }
}

@media (max-width: rem(1200)) {
  .dashboard-container {
    .divider {
      background-color: $primary;
      width: rem(100);
    }

    .banner-container {
      border-radius: rem(10);
      background: linear-gradient(
          95deg,
          rgba(172, 218, 251, 0.62) 1.79%,
          rgba(172, 218, 251, 0.12) 32.53%
        ),
        $white;

      box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);

      display: flex;
      padding: 1.5rem;
      align-items: center;
      width: 100%;

      .banner-title-container {
        width: 60%;
        color: $banner-background;
        gap: rem(24);

        .banner-title {
          color: $banner-background;
          font-family: "Open Sans Regular", sans-serif;
          font-size: rem(24);
          font-style: normal;
          font-weight: 600;
          line-height: rem(40);
        }

        .banner-text {
          color: black;
          font-family: "Open Sans Regular", sans-serif;
          font-size: rem(16);
          font-style: normal;
          font-weight: 400;
          line-height: rem(28);
        }
      }
    }

    .view-all-btn {
      color: var(--primary-6, $count-tag-bg);
      text-align: center;
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(22);
      cursor: pointer;
    }

    .dash-sub-title {
      color: $sub-title;
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(18);
      font-style: normal;
      font-weight: 600;
      line-height: rem(28);
      margin: 0;
    }

    .dash-title {
      color: $sub-title;
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(20);
      font-style: normal;
      font-weight: 600;
      line-height: rem(32);
      margin: 0;
      margin-bottom: rem(24);
    }
    .dcontainer {
      display: flex;
      flex-direction: column;
    }
    .dash-tile-container {
      width: 100%;
      row-gap: 1.5rem;
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }
  }
}
