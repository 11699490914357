@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.ant-menu-item :hover {
  background: transparent !important;
}

.navbar-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  &:hover {
    cursor: pointer;
  }
}
.navbar-logo-title {
  color: var(--neutral-1, $white);
  text-align: center;
  width: 100%;

  /* H4/medium */
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(20);
  font-style: normal;
  font-weight: 600;
  line-height: rem(28); /* 140% */
}
.nav-collapse {
  width: 100%;
  margin-top: 0.875rem;
}
.ant-menu-item {
  border-radius: rem(0) !important ;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
  width: 100% !important;
  height: 3rem !important;
  padding-left: 1.5rem !important;
  color: $white !important;
}
.menu-icon svg {
  color: $white !important;
}

.ant-menu-item:not(.ant-menu-item-selected) {
  fill: $white !important; /* Color for inactive (non-selected) menu items */
  background-color: transparent !important;
}
.menu-item:not(.ant-menu-item-selected) .menu-icon svg {
  fill: $menu-fill; /* Color for inactive (non-selected) menu item icons */
}
