@import "../styles/function";

$font-8: rem(8);
$font-9: rem(9);
$font-10: rem(10);
$font-11: rem(11);
$font-12: rem(12);
$font-13: rem(13);
$font-14: rem(14);
$font-15: rem(15);
$font-16: rem(16);
$font-17: rem(17);
$font-18: rem(18);
$font-19: rem(19);
$font-20: rem(20);
$font-21: rem(21);
$font-22: rem(22);
$font-24: rem(24);
$font-25: rem(25);
$font-26: rem(26);
$font-28: rem(28);
$font-30: rem(30);
$font-31: rem(31);
$font-32: rem(32);
$font-34: rem(34);
$font-36: rem(36);
$font-40: rem(40);
$font-48: rem(48);
$font-56: rem(56);
$icon-font-size: rem(24);
$icon-logout-font-size: rem(17);

$fontSize-label: 0.875em;
$fontSize-tag: 0.75em;
$fontSize-heading: 1.375em;
$fontSize-fontTwentyfour: 1.5em;
$fontSize-tiny: 0.625em;
$fontSize-tiny-medium: 0.875;
$fontSize-medium: 1em;
$fontSize-large: 1.188em;
$fontSize-fontEighteen: 1.125em;
$fontSize-extraLarge: 1.875em;
$fontSize-fontTwenty: 1.25em;
$fontSize-header: 0.9375em;
$fontSize-fontHeader: 1.1em;
$fontSize-fontThirteen: 0.97em;

$fontWeight-light: 300;
$fontWeight-light1: 400;
$fontWeight-medium: 500;
$fontWeight-medium2: 600;
$fontWeight-medium1: 700;
$fontWeight-bolder: 900;
$fontWeight-bold: bold;
$fontWeight-normal: normal;

// Break-points, giving parameters in pixels and getting corresponding rem

$break-xs: rem(480);
$break-sm: rem(576);
$break-md: rem(768);
$mobileToIpad: rem(767);
$break-mdl: rem(1024);
$ipadProToDesktop: rem(1025);
$break-lg: rem(992);
$break-xl: rem(1200);
$break-xxl: rem(1600);
$break-xxs: rem(375);
