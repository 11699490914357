@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.lesson-details-tile {
    border-radius: 6px;
    border: 1px solid var(--neutral-6, $evenTableRow);
    background: var(--neutral-1, $white);
    margin-bottom: rem(15);
    margin-right: 0.75rem;

    .lesson-details-tile-row {
        padding: 1rem;

        .image-arrow-down {
            width: 15%;
            margin-right: 0.7rem;
            position: relative;
            top: 0.0625rem;
            cursor: pointer;
        }

        .lesson-details-tile-heading {
            .title {
                font-size: rem(14);
                color: $black;
                font-weight: $fontWeight-light1;
            }

            .duration {
                font-size: rem(16);
                color: $dullGrey;
            }

            .ant-col:nth-child(2) {
                padding-right: 0 !important;
            }

            img {
                position: relative;
                top: rem(2);
                height: rem(25);
            }
        }

        .lesson-details-tile-icons {
            display: none;
        }
    }
}

.lesson-details-tile:hover .lesson-details-tile-row .lesson-details-tile-icons {
    display: block;
    transition: opacity 1s;
}