@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.lt-title {
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(18);
  padding: 0;
  margin: 0;
}

.mylearning-holder {
  row-gap: 2.25rem;
  width: 100%;
}

.my-learning-tabs {
  width: 98%;
}

.ant-tabs-nav {
  margin-bottom: 1.5rem !important;
}

.my-empty-div {
  width: 100%;
  margin-top: 1.5rem;
}

.mylearnings-tabs-indicator {
  border-radius: rem(100);
  background: var(--day-break-blue-7, $blue);
  width: rem(40);
  color: white;
  text-align: center;
}

.mylearnings-tabs-indicator-completed {
  border-radius: rem(100);
  background: var(--polar-green-8, $active-tag-text);
  width: rem(40);
  color: white;
  text-align: center;
}

.learning-tabs-header {
  column-gap: 1rem;
}

@media (max-width: rem(1440)) {
  .lt-title {
    font-size: rem(18);
    padding: 0;
    margin: 0;
    font-family: "Open Sans Regular", sans-serif;
    /* 140% */
  }
}
