@import "../../../styles/colors";
@import "../../../styles/function";
@import "../../../styles/fonts";
@import "../../../Theme/Theme";

.chat-box-start {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: $primary;
  background: $accent-blue;
  border-top-left-radius: rem(10);
  border-top-right-radius: rem(10);
  height: auto;
  box-shadow: rem(0) rem(-1) rem(0) rem(0) $shadow-border inset;
  .chat-bot-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    img.st-logo {
      height: 120%;
      margin-right: rem(8);
    }

    .chat-header {
      font-weight: $fontWeight-medium2;
      font-family: "Open Sans Regular", sans-serif;
    }
  }
}
