@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.rcourse-card {
  display: flex;
  padding: 1.5rem;
  gap: rem(24);
  border-radius: rem(10);
  background: var(--neutral-1, $white);
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  &:hover {
    box-shadow: 0 0.188rem 0.825rem rgb(0 0 0 / 0.2);
    cursor: pointer;
  }

  .rcourse-content {
    height: 100%;
    flex: 1;
  }
}
.rcourse-title {
  color: var(--neutral-13, $black);
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(20);
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: rem(10);
}
.rcourse-content {
  color: var(--neutral-13, $black);
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24);

  .rcourse-text {
    margin-top: rem(-10);
    min-height: 8rem;
    margin-bottom: 0.375rem !important;

    font-family: "Open Sans Regular", sans-serif;
  }
}
.rcourse-duration {
  display: flex;
  margin-bottom: 0;
  color: var(--neutral-7, $light-grey-text);
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(13.636);
  font-style: normal;
  font-weight: 600;
  line-height: rem(21.48);
  justify-content: center;
  margin-top: 0;
  gap: 1rem;
}
.bottom {
  margin-top: 4%;
}
.rcourse-image-container {
  display: flex;
  align-items: center;
  background: $imgLoadingBackround;
  animation: img-loading 1s linear infinite alternate;
  .rcourse-image {
    border-radius: rem(6);
    max-width: rem(300);
    height: 12.75 rem;
    object-fit: cover;
    width: 12.5rem;
  }
}
