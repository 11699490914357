@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.main-container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .main-container-row {
    margin-top: rem(-7);
  }

  .divider-container {
    margin-bottom: rem(-5);

    .divider {
      background: $primary;
    }
  }

  .bc-parent {
    cursor: pointer;
    font-size: rem(16);
    color: $primary;
    font-weight: $fontWeight-bold;
  }

  .bc-child {
    cursor: not-allowed;
    font-size: rem(16);
    color: $text-grey;
  }
}
