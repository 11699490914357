@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.quiz-question-section .ant-col:last-child {
  min-height: 4rem;
}

.quiz-question-section {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  max-height: 75vh;
  // overflow: auto;

  &::-webkit-scrollbar {
    width: rem(2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(5);
    background-color: $text-grey;
  }

  .assessment-border {
    .solution-content {
      font-family: "Open Sans Regular", sans-serif;
    }

    border-radius: rem(6);
    border: rem(1) solid var(--neutral-6, $evenTableRow);
    padding: rem(12);
    overflow: auto;
    // .area-of-improvement {
    //   margin-top: 1rem;
    // }
    .task {
      margin-top: 1rem;
    }
    .eval-title {
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(14);
      font-weight: $fontWeight-medium2;
      display: flex;
      align-items: center;
      justify-content: normal;
      width: 100%;
      margin-bottom: 0;
    }
    .eval-score {
      justify-content: normal;
      font-family: "Open Sans Regular", sans-serif;
      strong {
        margin-right: rem(10);
      }
    }
  }
  s .item-1 {
    color: $red;
  }

  .mark-content {
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-weight: $fontWeight-medium2;
  }

  .item-2 {
    color: $primary;
  }

  .answered-status {
    display: flex;
    text-align: center;

    span {
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(16);
      font-weight: $fontWeight-light1;
    }
  }
}

.quiz-question-section-question {
  .question-content {
    font-size: rem(16);
    font-family: "Open Sans Regular", sans-serif;
    font-weight: $fontWeight-medium2;
    color: $primary;
  }
}

// .quiz-question-section .ant-col:nth-child(2) {
//   color: $primary;
//   font-weight: $fontWeight-medium2;
// }

.quiz-next-button {
  border-radius: 0%;
  background-color: $primary;
  color: $white;
  font-family: "Open Sans Regular", sans-serif;
  font-weight: $fontWeight-light1;
  font-size: rem(16);
}

.assessment-submit-button {
  height: rem(43);
  padding: rem(3.896) rem(14.61);
  border-radius: 0%;
  background-color: $primary;
  color: $white;
  font-family: "Open Sans Regular", sans-serif;
  font-weight: $fontWeight-light1;
  font-size: rem(16);
}

.quiz-back-button {
  border-radius: 0%;
  background-color: $white;
  color: $primary;
  font-family: "Open Sans Regular", sans-serif;
  font-weight: $fontWeight-light1;
  font-size: rem(16);
}
.pdf-loading-spinner {
  width: 100%;
  height: calc(70vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(16);
}
.verdict {
  justify-content: normal !important;

  .verdict-details {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    // margin-left: 2rem;
  }
  .verdict-details:last-child {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
  }
}
