/**
 * theme.js contains all the styles for this project.
 */

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v36-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v36-latin-regular.ttf") format("truetype");
}
/* open-sans-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/open-sans-v36-latin-500.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v36-latin-500.ttf") format("truetype");
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Semi Bold";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/open-sans-v36-latin-600.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v36-latin-600.ttf") format("truetype");
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v36-latin-700.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v36-latin-700.ttf") format("truetype");
}
/* open-sans-800 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Extra Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/open-sans-v36-latin-800.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v36-latin-800.ttf") format("truetype");
}

$fontFamily-regular: "Open Sans Regular", sans-serif;
$fontFamily-medium: "Open Sans Medium";
$fontFamily-semi-bold: "Open Sans Semi Bold";
$fontFamily-bold: "Open Sans Bold";
$fontFamily-extra-bold: "Open Sans Extra Bold";

$secondary: #153f68;
$tertiary: #7cbfff;
$header-background: #08414e;
$error: #dc2020;
$warning: #dcaa20;
$success: #29823b;
$disabled: #676767;
$app-background: #f0f2f5;

$fontSize-label: 0.875em;
$fontSize-tag: 0.75em;
$fontSize-heading: 1.375em;
$fontSize-fontTwentyfour: 1.5em;
$fontSize-fontSixteen: 1em;
$fontSize-tiny: 0.625em;
$fontSize-tiny-medium: 0.875;
$fontSize-medium: 1em;
$fontSize-large: 1.188em;
$fontSize-fontEighteen: 1.125em;
$fontSize-extraLarge: 1.875em;
$fontSize-fontTwenty: 1.25em;
$fontSize-header: 0.9375em;
$fontSize-fontHeader: 1.1em;
$fontSize-fontThirteen: 0.97em;

$fontWeight-light: 300;
$fontWeight-medium: 500;
$fontWeight-bolder: 900;
$fontWeight-bold: bold;
$fontWeight-normal: normal;

$height: 100vh;
$width-10: 10%;
$width-20: 20%;
$width-30: 30%;
$width-40: 40%;
$width-50: 50%;
$width-60: 60%;
$width-70: 70%;
$width-80: 80%;
$width-89: 89%;
$width-90: 90%;
$width-93: 93%;
$width-95: 95%;
$width-100: 100%;
$width-110: 110%;

$height-10: 10%;
$height-20: 20%;
$height-30: 30%;
$height-40: 40%;
$height-50: 50%;
$height-60: 60%;
$height-70: 70%;
$height-80: 80%;
$height-90: 90%;
$height-100: 100%;

$chat-bot-height: 85vh;

$lineheight-TableHead: initial;
$lineheight-medium: 2em;
