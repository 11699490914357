@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.assessment-parent-container {
  border-radius: rem(6);
  border: rem(1) solid var(--neutral-5, $lightGrey);
  padding: rem(24);
  margin-right: rem(12) !important;
  .quiz-details-container {
    width: 100%;
    .quiz-details-form {
      width: 100%;
    }
  }
}
.generate-assessment-container {
  display: flex;
  justify-content: space-between;
  margin-left: rem(0) !important;

  width: 100%;

  .assessment-question-box {
    border-radius: rem(6);
    border: rem(1) solid var(--neutral-6, $evenTableRow);
    background: var(--neutral-1, $white);
    padding: rem(24);
    flex: 0 0 69.5% !important;
    max-width: 69.5% !important;

    .label {
      font-size: rem(14);
      font-family: "Open Sans Regular", sans-serif;
    }

    .question-box-title {
      display: flex;
      padding: rem(12);
      align-items: flex-start;
      gap: rem(-32);
      border-radius: rem(4);
      background: $lightestGrey;
      justify-content: space-between;

      .question-box-title-text {
        color: $black;
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(14);
        font-style: normal;
        font-weight: 600;
        line-height: rem(22);
      }

      .generated-text {
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(14);
      }
    }

    .generate-assessment-image-container {
      display: flex;
      justify-content: center;
      padding: rem(24);
      flex: 0 0 69.5% !important;
      max-width: 69.5% !important;
    }

    .generate-assessment-text-container {
      width: 100%;
      display: flex;
      justify-content: center;
      color: var(--neutral-8, $steps-subtitle);
      text-align: center;
      font-size: rem(20);
      font-style: normal;
      font-weight: 400;
      line-height: rem(28);

      /* 140% */
      .generate-assessment-text-container-span {
        width: 60%;
      }
    }

    .generate-assessment-image {
      width: rem(300);
      height: rem(300);
    }
  }

  .generate-assessment-question-box {
    border-radius: rem(6);
    border: rem(1) solid var(--neutral-6, $evenTableRow);
    background: var(--neutral-1, $white);
    padding: rem(24);
    max-height: rem(600);

    .label {
      font-size: rem(14);
      font-family: "Open Sans Regular", sans-serif;
    }

    .generate-question-button {
      border-radius: rem(2);
      border: rem(1) solid var(--primary-6, $count-tag-bg);
      background: var(--primary-6, $count-tag-bg);
      box-shadow: rem(0) rem(2) rem(0) rem(0) rgba(0, 0, 0, 0.04);
      display: flex;
      width: 100%;
      height: rem(47);
      padding: rem(4) rem(15);
      justify-content: center;
      font-size: rem(14);
      font-family: "Open Sans Regular", sans-serif;
      align-items: center;
      gap: rem(8);
      color: white;

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    }

    .question-box-title {
      display: flex;
      padding: rem(12);
      align-items: flex-start;
      gap: rem(-32);
      border-radius: rem(4);
      background: $lightestGrey;

      .question-box-title-text {
        color: $black;
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(14);
        font-style: normal;
        font-weight: 600;
        line-height: rem(22);
      }
    }

    .question-box-title .ant-row:last-child span {
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(14);
    }
  }
}

.content-right {
  text-align: right;
  margin-top: rem(15);

  cursor: pointer;
}

.radio-item,
.checkbox-item {
  padding: rem(15);
  padding-left: rem(30);
  margin-bottom: rem(20);
  background: $white;
  border-radius: rem(10);
  border: rem(1) solid $box-shadow-bg;
  height: auto;
}

.save-assessment-button {
  border: rem(1) solid var(--primary-6, $count-tag-bg);
  background: var(--primary-6, $count-tag-bg);
  box-shadow: rem(0) rem(2) rem(0) rem(0) rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  font-size: rem(14);
  font-family: "Open Sans Regular", sans-serif;
  align-items: center;
  gap: rem(8);
  color: white;
}

.ql-editor {
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(16);
}

@media (max-width: rem(1330)) {
  .generate-assessment-container .assessment-question-box {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .generate-assessment-container .generate-assessment-question-box {
    max-height: none;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

#testcases_help,
#evaluationCriteria_help,
#requirements_help,
#title_help {
  margin-top: 3rem;
}
.assessment-details-tile-icons {
  display: none;
}
.assessment-questions-tile:hover .assessment-details-tile-icons {
  display: block;
  transition: opacity 1s;
}
