@import "../../Theme/Theme";
@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/function";

.breadcrumb-label {
  font-size: rem(16);
  font-family: "Open Sans Regular", sans-serif;
}
