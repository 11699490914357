@import "./function";
@import "./colors";
@import "../Theme/Theme";

.font-osr {
  font-family: $fontFamily-regular;
}

.header {
  font-family: $fontFamily-medium;
  font-weight: $fontWeight-light1;
  font-size: $font-34;
  letter-spacing: 0.005em;
  text-align: left;
}

.header-4 {
  font-family: $fontFamily-medium;
  font-size: $font-24;
  font-weight: $fontWeight-light1;
  margin: 0;
}

.header-5 {
  //styleName: Desktop / H5 / Semibold;
  font-size: $font-20;
  font-family: $fontFamily-medium;
  font-weight: $fontWeight-light1;
  text-align: left;
  margin: 0 !important;
}

.header-6 {
  //styleName: Heading/H6 Bold;
  font-family: $fontFamily-medium;
  font-weight: $fontWeight-light1;
  font-size: $font-16;
  line-height: rem(16);
  letter-spacing: 0.005em;
}

.caption {
  font-family: $fontFamily-regular;
  font-size: $font-12;
  font-weight: $fontWeight-light1;
}

.body-1 {
  //styleName: Body/Body 1 Regular;
  font-family: $fontFamily-regular;
  font-size: $font-16;
  font-weight: $fontWeight-light1;
  letter-spacing: 0.005em;
}

.body-2 {
  //styleName: Body/Body 2 Regular;
  font-family: $fontFamily-regular;
  font-size: $font-14;
  font-weight: $fontWeight-light1;
  line-height: rem(18);
  letter-spacing: 0.008em;
  text-align: left;
  color: $light-text;
}
.light-text {
  font-family: $fontFamily-regular;
  font-size: $font-16;
  letter-spacing: 0.008em;
  color: $light-text;
  font-weight: $fontWeight-medium2;
}

.title {
  font-family: $fontFamily-regular;
  font-size: $font-17;
  font-weight: $fontWeight-medium1;
  line-height: rem(20);
  letter-spacing: 0.008em;
}

.link {
  font-family: $fontFamily-regular;
  font-size: $font-17;
  font-weight: $fontWeight-medium1;
  line-height: rem(20);
  letter-spacing: 0.008em;
  color: $hoverButton;
  text-decoration: underline;
}

.custom-notification {
  color: $white;
  font-weight: $fontWeight-bold;

  .ant-notification-notice-message {
    margin: 0;
  }
}

.error-notification {
  background-color: $error;
}

.success-notification {
  background-color: $success;
}

.cursor {
  cursor: pointer;
}

//table styling
.ant-table {
  margin: rem(8);

  .ant-table .ant-table-thead > tr > th {
    background-color: none;
    font-weight: $fontWeight-bold;
    border: none;
    color: $kashmirBlue;
  }

  .ant-table-tbody > tr > td {
    border-bottom: rem(2) solid $table-content-preview;
    font-size: $font-12;
  }

  .ant-table-container {
    background: $table-content-preview 0% 0% no-repeat padding-box;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-row {
    background: $white 0% 0% no-repeat padding-box;
    border: rem(1) solid $background-grey;
    border-radius: rem(8);
  }

  .ant-table {
    background: none;
  }
}

.mandatory {
  color: $red;
}

.navbar-header {
  display: grid;
  grid-template-columns: rem(300) auto;
  background: $black;
  /* padding: rem(8); */
  position: fixed;
  width: 100%;
  z-index: 1000;
}

@media only screen and (max-width: rem(900)) {
  .navbar-header {
    grid-template-columns: auto;
  }
}

.navbar-body {
  display: flex;
  list-style-type: none;
  margin: auto;
  align-items: center;
  padding: rem(10);
}

.navbar-header-inner {
  justify-content: center;
  /* padding: rem(10); */
  margin: auto;
  font-size: x-large;
  color: $white;
}

.nav-link {
  text-decoration: none;
  color: $white;
  /* font-size: medium;
    font-weight: bolder; */
}

.profile-card {
  display: grid;
  justify-items: center;
  position: absolute;
  align-items: center;
  right: rem(8);
  /* margin: 1em; */
}

.profile-pic {
  display: grid;
  grid-row: 1/3;
}

.logout-url {
  display: grid;
  grid-column: 2;
  // color: $aliceBlue;
  padding: rem(4);
  border: rem(1) solid $white;
  width: rem(118);
  text-align: center;
  /* height: rem(27); */
  /* height: rem(32); */
  border-radius: rem(40);
}

.icon-style {
  svg {
    font-size: $font-21;
  }
}

.ant-layout-sider-children .ant-menu {
  background: transparent;
}

.menu-style {
  svg {
    font-size: rem(28);
    color: $black;
    margin: rem(20) rem(0) rem(5) rem(22);
    background: $white;
    padding: rem(5);
    border-radius: rem(10);
  }

  &--expanded {
    svg {
      margin-left: rem(22);
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.white-bg {
  background: $white;
}

.ant-menu.ant-menu .ant-menu-item.modifiedItem,
.ant-menu-submenu,
.ant-menu.ant-menu .ant-menu-item.nav-link,
.ant-menu .ant-menu-submenu,
.ant-menu.ant-menu .ant-menu-item-selected.modifiedItem,
.ant-menu-submenu-selected,
.ant-menu.ant-menu .ant-menu-item-selected.nav-link,
.ant-menu .ant-menu-submenu-selected {
  border-radius: rem(5);
  background-color: $white;
  /*Overriden property*/
  color: white;
  /*Overriden property*/
  font-weight: $fontWeight-bolder;
  margin: rem(10);
  width: 90%;

  span {
    a {
      display: flex;
      text-decoration: none;
      color: white;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.ant-menu.ant-menu .ant-menu-item-selected.modifiedItem,
.ant-menu-submenu-selected,
.ant-menu.ant-menu .ant-menu-item-selected.nav-link,
.ant-menu .ant-menu-submenu-selected {
  background-color: $primary;
  /*Overriden property*/
  color: $white;

  /*Overriden property*/
  span {
    a {
      color: $white;
    }
  }
}

.sider .ant-menu-light .ant-menu-submenu-title {
  display: flex;
  align-items: center;

  &:hover {
    color: $black;
  }
}

.sider
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: $black;
}

.menu-icon {
  svg {
    margin-top: rem(8);
  }

  .add-content-icon {
    //This is required because the svg dimension is not uniform as of other icons
    margin: rem(7) rem(-7) rem(0) rem(-3);
  }
}

.ant-layout-sider.ant-layout-sider-collapsed {
  .menu-name {
    display: none;
  }

  .menu-icon {
    padding-left: rem(13.28);
  }
}

.ant-layout-sider {
  .menu-name {
    display: inline-block;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .menu-icon {
    display: none;
  }
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  .ant-menu-item.modifiedItem {
    width: 70%;
    margin: rem(10) 16.25%;
    padding: 0;
  }

  .ant-menu-title-content {
    width: rem(56);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fontWeight-medium1;
  }
}

.sider {
  box-shadow: 1px 1rem 0.5rem $box-shadow-bg;
  z-index: 1;
  .menu-icon {
    justify-self: center;
  }
}

.no-border {
  border: none !important;
  box-shadow: none;
}

.error-text {
  color: $darkRed;
}

.success-text {
  color: $success-text;
}

.ant-alert {
  border-radius: rem(4);
}

//tab styling
.app-tabs {
  margin: rem(4) 0%;

  .ant-tabs-nav {
    margin: rem(0);

    &:before {
      border: none;
    }

    .ant-tag {
      background-color: $lightGrey;
      color: $mediumGrey;
      border-radius: rem(100);
      margin-left: rem(4);
      margin-right: 0;
    }

    .ant-tabs-extra-content {
      margin-bottom: rem(7);
    }

    .ant-tabs-nav-wrap {
      margin-bottom: rem(12);
    }

    .ant-tabs-ink-bar {
      background-color: $primary;
      height: rem(3);
    }

    .ant-tabs-tab {
      background-color: transparent;
      color: $light-grey-text;
      border: none;
      padding: rem(1) rem(0);
      margin: rem(10) rem(24) rem(10) rem(0);
      font-weight: $fontWeight-medium;
      font-size: $font-18;
    }

    .ant-tabs-tab-active {
      color: $primary;
      font-weight: $fontWeight-medium1;

      .ant-tabs-tab-btn {
        background-color: transparent;
        color: $primary;
      }

      .ant-tag {
        background-color: $count-tag-bg;
        border-color: $count-tag-bg;
        color: $white;
      }
    }
  }
}

//breadcrumb style
.breadcrumb-header {
  background-color: $white;

  .header-title {
    font-weight: $fontWeight-medium1;
    font-size: $font-19;

    .edit-tdg-btn {
      background-color: $get-started-btn-bg;
      color: $white;
      margin-right: rem(15);
    }
  }
}

.custom-table {
  .ant-table {
    margin: 0;

    .ant-table-content {
      padding: 0;

      .ant-table-cell {
        padding: rem(16);
        border: rem(2);
      }
    }

    .ant-table-row:nth-child(even) {
      background-color: $background-color-grey;

      .ant-table-cell-fix-left,
      .ant-table-cell-fix-right {
        background-color: $background-color-grey;
      }
    }

    //required for overwriting nth child style
    .ant-table-row-selected {
      background-color: $selected-table-row !important;

      td {
        background-color: $selected-table-row !important;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        border-width: 1px 0px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}

.ant-table-empty .ant-table-tbody {
  background-color: $white;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.primary-icon {
  color: $primary;
}

.app-tag {
  background-color: $count-tag-bg;
  border-color: $count-tag-bg;
  color: $white;
  border-radius: rem(100);
  margin-left: rem(4);
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.edit-btns {
  color: $accessible-blue;
  font-size: $font-14;
  cursor: pointer;
  padding: 0;
}

.edge-tag {
  background: $edge-background;
  border: rem(1) solid $edge-border;
  border-radius: rem(2);
  color: $edge-clr;
  height: fit-content;
}

.spark-tag {
  background: $spark-background;
  border: rem(1) solid $spark-border;
  border-radius: rem(2);
  color: $spark;
  height: fit-content;
}

.info-header-container {
  background-color: $white;
  .info-header {
    font-weight: $fontWeight-medium;
    font-size: $font-16;
  }
}

.info-section {
  box-shadow: inset 0 rem(1) 0 $shadow-border;
  background-color: $white;
  padding: rem(20);
}

.bold-text {
  font-weight: bold;
}

.primary-text {
  color: $primary;
}

.disabled {
  color: $get-started-btn-text-disabled;
}

.info-text {
  color: $grey;
  margin-bottom: rem(10);
  font-style: italic;
  font-size: $font-14;
}

.table-tooltip {
  background-color: white;
  color: black;
  font-size: $font-10;
  max-width: rem(500);
}

.nav-collapse {
  width: rem(46);
  height: rem(46);
}

.percent-text-container {
  background-color: $disabled-bg-color;
  border-radius: rem(5);
  padding: rem(10);

  .percent {
    color: $primary;
    font-size: rem(28);
    font-weight: 600;
    text-align: center;
  }

  .percent-text {
    font-size: $font-14;
    font-weight: 600;
    text-align: center;
  }

  .time-frame {
    font-size: $font-14;
  }
}
