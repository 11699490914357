@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.section-form-container {
  background: $white;
  border-radius: rem(10);
  border: rem(1) solid $box-shadow-bg;
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  height: auto;
  padding-bottom: rem(15);

  .section-cards-container {
    margin-top: rem(7);
  }

  .section-form-header {
    height: auto;
    padding: rem(16);
    border-top-left-radius: rem(10);
    border-top-right-radius: rem(10);

    .section-form-header-title {
      font-weight: $fontWeight-medium2;
    }

    span {
      padding-left: rem(12);
    }

    .breadcrumb-divider {
      background-color: $evenTableRow;
      width: calc(100% - 1.5rem);
      margin-left: 0.75rem;
    }
  }

  .section-tile-container {
    background: $white;
    border-radius: rem(10);
    border: rem(1) solid $box-shadow-bg;
    height: auto;
    padding-bottom: rem(15);
    padding-top: rem(10) !important;
  }

  .section-form-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .add-section-tile {
      border-radius: 6px;
      border: 1px solid var(--neutral-6, $evenTableRow);
      background: var(--neutral-1, $white);
      padding: 1rem;

      .section-form-label span {
        font-family: "Open Sans Regular", sans-serif;
      }

      .ant-col {
        padding-left: 0 !important;
      }

      .form-add-section-btn-col-p {
        padding-right: 0 !important;
        margin-top: rem(20);
        margin-bottom: 1rem;

        .form-add-section-btn-col {
          padding-right: 0 !important;

          .form-add-section-button {
            border-radius: 0%;
            background-color: $white;
            color: $primary;
            margin: 0;
          }
        }
      }
    }
  }
}

.listItem {
  border: 2px solid black;
  margin: 5px;
  width: 400px;
  cursor: grab;
  z-index: 1;
  transition: scale 0.3s ease-in-out;
  background-color: white;
}

.draggable {
  border: 2px solid hotpink;
  margin: 5px;
  width: 400px;
  cursor: grab;
  scale: 108%;
  z-index: 10;
  transition: scale 0.3s ease-in-out;
  background-color: white;
}

.listItem:-moz-drag-over {
  cursor: pointer;
}
