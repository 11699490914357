@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.lesson-container {
  padding-left: 0 !important;
  
  .lesson-tile-container {
    margin: auto;
    justify-content: center;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .add-lesson-tile {
      border-radius: 6px;
      border: 1px solid var(--neutral-6, $evenTableRow);
      background: var(--neutral-1, $white);

      .ant-col {
        padding-left: 0 !important;
      }

      .label {
        font-size: rem(14);
        font-family: "Open Sans Regular", sans-serif;
        font-weight: $fontWeight-medium2;
      }

      .form-add-lesson-btn-col-p {
        padding-right: 0 !important;
        margin-top: 3rem;
        margin-bottom: 1rem;

        .form-add-lesson-btn-col {
          padding-right: 0 !important;

          .form-add-lesson-button {
            border-radius: 0%;
            background-color: $white;
            color: $primary;
            margin: 0;
          }
        }
      }
    }
  }
}

.lesson-modal {
  .ant-modal-content {
    height: rem(220) !important;

    .ant-modal-header {
      height: rem(200);

      .ant-modal-title {
        display: flex;
        flex-direction: column;
        height: rem(180);
      }
    }

    .ant-modal-close-icon {
      margin-left: rem(10);
    }
  }

  .modal-title {
    display: flex;
    align-items: start;

    .modal-title-image {
      margin-right: rem(10);
      margin-top: rem(6);
    }
  }

  .modal-content {
    margin-left: rem(24);
    padding-top: rem(20);
  }
}