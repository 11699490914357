@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.course-cards-container {
  .course-card {
    width: 28%;
    padding: rem(18);
    border-radius: rem(9);
    cursor: pointer;
    padding-bottom: rem(6);
    min-width: 25rem;
  }

  .add-course-card {
    color: $primary;
    border: rem(2) dashed $primary;

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        width: rem(40);
        height: rem(70);
      }

      .seperator {
        color: $primary;
      }

      .add-course-section {
        height: 100%;
        width: 100%;
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .my-content-card-container {
    gap: 2rem;
  }
}

.ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  width: 0;
}
.ant-divider-horizontal {
  min-width: 25%;
}

.mycontent-container {
  background: $white;
  border-radius: rem(10);
  border: rem(1) solid $box-shadow-bg;
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  height: 75vh;
  padding-bottom: rem(15);
  display: flex;
  align-items: center;
  justify-content: center;

  .mycontent-image-container {
    display: flex;
    justify-content: center;
    .my-content-image {
      width: rem(300);
      height: rem(300);
    }
  }
  .mycontent-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--neutral-8, $steps-subtitle);
    text-align: center;
    font-size: rem(20);
    font-style: normal;
    font-weight: 400;
    line-height: rem(28); /* 140% */
    .mycontent-text-container-span {
      width: 40%;
    }
  }
  .mycontent-button-container {
    display: flex;
    justify-content: center;
    .mycontent-button {
      border-radius: 2px;
      border: 1px solid #3b818d;
      background: var(--Primary-Blue, $primary);
      font-family: "Open Sans Regular", sans-serif;

      /* drop-shadow/button-primary */
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      color: white;
      display: flex;
      width: rem(163.129);
      height: rem(50);
      padding: rem(6.4) rem(15);
      justify-content: center;
      align-items: center;
      gap: rem(10);
      flex-shrink: 0;
    }
  }
}
.my-content-header {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));

  /* H4/medium */
  font-size: rem(20);
  font-style: normal;
  font-weight: 600;
  line-height: rem(28); /* 140% */
  font-family: "Open Sans Regular", sans-serif;
}
.mycontent-button {
  border-radius: 2px;
  border: 1px solid #3b818d;
  background: var(--Primary-Blue, $primary);
  font-family: "Open Sans Regular", sans-serif;

  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: white;
  display: flex;
  width: rem(163.129);
  height: rem(50);
  padding: rem(6.4) rem(15);
  justify-content: center;
  align-items: center;
  gap: rem(10);
  flex-shrink: 0;
}
.mycontent-header-container {
  width: 100%;
}

.ant-table-wrapper {
  width: 100% !important;
}
.table-item-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));

  /* Body/regular */
  font-size: rem(14);
  font-style: normal;
  font-weight: 400;
  line-height: rem(22); /* 157.143% */
}
.mycontent-details-tile-buttons {
  font-size: rem(18);
}

@media (max-width: rem(1200)) {
  .mycontent-container {
    .mycontent-image-container {
      display: flex;
      justify-content: center;
      .my-content-image {
        width: rem(150);
        height: rem(150);
      }
    }
  }
}

.ant-table-cell {
  font-family: "Open Sans Regular", sans-serif;
}
