@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.spin-container {
  display: flex;
  justify-content: center;
}

.question-container {
  border-radius: rem(6);
  border: rem(1) solid var(--neutral-5, $lightGrey);
  padding: rem(24);
  display: flex;
  justify-content: space-between;
  margin-left: rem(0) !important;
  margin-right: rem(10) !important;

  .upload-content-container {
    margin-right: rem(-22);
    width: 100%;

    .upload-content-question-box {
      border-radius: rem(6);
      border: rem(1) solid var(--neutral-6, $evenTableRow);
      background: var(--neutral-1, $white);
      padding: rem(24);

      .question-box-title {
        display: flex;
        padding: rem(12);
        align-items: flex-start;
        gap: rem(-32);
        border-radius: rem(4);
        background: $lightestGrey;

        .course-question-header {
          font-weight: $fontWeight-medium1;
          font-size: rem(14);
          display: block;
          color: $primary;
        }
      }

      .question-box-content {
        margin-top: rem(16);

        .question-box-content-header {
          font-size: rem(14);
          font-family: "Open Sans Regular", sans-serif;
        }
      }

      .upload-content {
        background-color: $shadow-border;
        margin-top: rem(16);
        padding: rem(8);
        display: flex;
        justify-content: space-between;

        .upload-content-text {
          color: $primary;
          font-family: "Open Sans Regular", sans-serif;
          font-size: rem(14);
        }
      }
    }
  }

  .upload-doc-container {
    border-radius: rem(6);
    border: rem(1) solid var(--neutral-5, $lightGrey);
    padding: rem(24);
    display: flex;
    justify-content: space-between;
    margin-left: rem(0) !important;
    margin-right: rem(10) !important;
  }

  .course-content-question-header {
    width: 100%;

    span {
      color: $primary;
    }

    height: auto;
    padding: rem(16);
    background: $accent-blue;
    border-radius: rem(6);

    .course-question-header {
      font-weight: $fontWeight-medium1;
      font-size: rem(18);
      display: block;
    }

    .content-question-left {
      text-align: left;
      cursor: pointer;

      .ant-typography {
        margin-bottom: 0 !important;
      }
    }

    .content-question-right {
      margin-top: 0 !important;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: right;
      
      .caret-icon {
        display: flex;
        align-items: center;
      }

      .slide-container-text {
        color: $black;
      }
    }
  }

  .add-question-box-container {
    display: flex;
    justify-content: space-between;

    .label {
      font-family: "Open Sans Regular", sans-serif;
    }

    .save-assessment-button {
      border: rem(1) solid var(--primary-6, $count-tag-bg);
      background: var(--primary-6, $count-tag-bg);
      box-shadow: rem(0) rem(2) rem(0) rem(0) rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: center;
      font-size: rem(14);
      font-family: "Open Sans Regular", sans-serif;
      align-items: center;
      gap: rem(8);
      color: white;
    }

    .course-media-question-container {
      box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
      background: $white;
      padding: 0 !important;
      border-radius: rem(10);
      height: rem(585);
      margin-left: rem(-12);
      flex: 0 0 60%;
      max-width: 60%;

      .pdf-question-container {
        width: 100%;
        height: calc(rem(560) - rem(70));
        padding: rem(16);
      }
    }

    .generate-content-container {
      margin-right: rem(-22);
      flex: 0 0 41%;
      max-width: 41%;

      .generate-content-question-box {
        border-radius: rem(6);
        border: rem(1) solid var(--neutral-6, $evenTableRow);
        background: var(--neutral-1, $white);
        padding: rem(24);
        max-height: rem(585);
        overflow: scroll;

        .button-container {
          margin-top: rem(-30);
        }

        .generate-question-button {
          border-radius: rem(2);
          border: rem(1) solid var(--primary-6, $count-tag-bg);
          background: var(--primary-6, $count-tag-bg);
          box-shadow: rem(0) rem(2) rem(0) rem(0) rgba(0, 0, 0, 0.04);
          display: flex;
          width: 100%;
          height: rem(47);
          padding: rem(4) rem(15);
          justify-content: center;
          font-size: rem(14);
          font-family: "Open Sans Regular", sans-serif;
          align-items: center;
          gap: rem(8);
          color: white;
    
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
          }
        }

        &::-webkit-scrollbar {
          width: rem(5);
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: rem(5);
          background-color: $text-grey;
        }

        .question-box-title {
          display: flex;
          padding: rem(12);
          align-items: flex-start;
          gap: rem(-32);
          border-radius: rem(4);
          background: $lightestGrey;
    
          .question-box-title-text {
            color: $black;
            font-family: "Open Sans Regular", sans-serif;
            font-size: rem(14);
            font-style: normal;
            font-weight: 600;
            line-height: rem(22);
          }
        }
      }
    }
  }
}