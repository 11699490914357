@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.coursecard-container {
  padding: 1rem;
  border-radius: rem(10);
  background: var(--neutral-1, $white);
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  max-width: 23rem;
  min-width: 23rem;

  &:hover {
    box-shadow: 0 0.188rem 0.825rem rgb(0 0 0 / 0.2);
    cursor: pointer;
  }

  .ccontainer {
    height: rem(166);
    border-radius: rem(6);
    background: $imgLoadingBackround;
    animation: img-loading 1s linear infinite alternate;

    .course-card-image {
      max-height: rem(166);
      width: 100%;
      border-radius: rem(10);
      object-fit: cover;
    }
  }

  .course-title {
    margin: 0;
    color: var(--neutral-13, $black);
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(20);
    font-style: normal;
    font-weight: 600;
  }

  .course-duration {
    display: flex;
    margin-bottom: 0;
    color: var(--neutral-7, #8c8c8c);
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(13.636);
    font-style: normal;
    font-weight: 600;
    line-height: rem(21.48);
    justify-content: center;
    margin-top: 0;
    gap: 0.9rem;
  }

  .course-card-text {
    color: var(--neutral-13, $black);
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
    text-align: justify;
  }
}

.coursecard-container .ant-row:last-child {
  align-items: center;
  bottom: rem(16);
  position: absolute;
  width: 92%;
}

.coursecard-container .ant-row:nth-child(2) {
  align-items: center;
  margin-top: rem(16);
}

.coursecard-container .ant-row:nth-child(3) {
  min-height: rem(50);
  margin-bottom: rem(48);
}

.ant-typography {
  margin-bottom: -0.375rem !important;
}
