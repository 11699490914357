@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.enroll-btn {
  border-radius: rem(1.948);
  border: rem(0.974) solid var(--primary-6, $count-tag-bg);
  background: var(--Primary-Blue, $primary);
  box-shadow: rem(0) rem(1.94798) rem(0) rem(0) rgba(0, 0, 0, 0.04);

  display: flex;
  width: rem(109.147);
  height: rem(38);
  padding: rem(6.234) rem(14.61);
  justify-content: center;
  align-items: center;
  gap: rem(9.74);
  flex-shrink: 0;
  color: $white;
  font-size: rem(16);
}
