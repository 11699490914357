//Landing page
$landingPageBg: #fafafa;

$black: #000;
$imgLoadingBackround: #1a1919f6;
$white: #fff;
$red: #e43f5a;
$navbarIcons: #9ab0a0;
$textMuted: #4f575e;
$darkText: #1a2d11;
$text-orange: #f1870d;
$text-grey: #92929d;
$light-text: #9a9ab0;
$status-count: #1e5966;
$borderGrey: #d3dcc7;
$background-muted: #f6f6f6;
$selected-card: #f3f8f9;
$fill-orange: #bc6d24;
$collapseHeader: #eef5f7;
$clock-green: #ebfbd0;
$background-red: #fffbfb;
$background-green: #fbfffc;
$background-color-grey: #fafafa;
$correct-green: #52c41a;
$banner-background: #235683;

$form-disabled-background: #ececec;
$blackGrey: #262626;
$tile-cline: #9e1068;
$tile-pline: #006d75;
$above: rgb(53, 104, 89);
$meets-above: rgb(55, 153, 111);
$meets: rgb(248, 203, 46);
$meets-below: rgb(249, 170, 51);
$below: rgb(253, 85, 35);
$get-started-btn-bg: #1b849b;
$get-started-btn-text-disabled: #b8b8b8;
$review-background: #e8f3f5;
$review-marked-section: #fff3f4;
$active-tag-bg: #f6ffed;
$active-tag-border: #389e0d;
$active-tag-text: #237804;
$inactive-tag-bg: #fff7e6;
$inactive-tag-border: #ffd591;
$inactive-tag-text: #fa8c16;
$selected-table-row: #e6f7ff;
$test-case-pie-pass: #90be6d;
$test-case-pie-fail: #f73d3d;
$box-shadow-bg: #00000026;

//bootcamp
$primary: #357bac;
$secondary: #153f68;
$tertiary: #7cbfff;
$statusCard: #fbfbfb;

$lightRed: #ff6b83;
$darkRed: #fa423b;
$green: #649d66;
$lightGreen: #87e08a;
$themeGreenMuted: #d6eee3;
$themeGreen: #007364;
$greenYellow: #adff2f;
$greenWhite: #beeedd;
$grey: #979797;
$darkGrey: #0000e6;
$grey32: #525252;
$color-1: #5b94bb;
$color-2: #3575a3;
$color-3: #114e79;

$slateGrey: #708090;
$dullGrey: #a9a9a9;
$lighterGrey: #efeff6;
$transparentGrey: #787f8540;
$textPrimary: #3d405c;
$lightBlue: #a6d2fc;
$hoverButton: #1890ff;
$lightCyanBlue: #b8c2d3;
$kashmirBlue: #536789;
$darkCyanBlue: #0b294e;
$aliceBlue: #f0f8ff;
$dimGrey: #d2d2e4;
$orange: #ff6d00;
$transparent: #00ffff;
$lightBlack: #323232;
$smokeBlack: #0000004d;
$greyShade: #f0f2f5;
$blackShade: #2b354a;
$yellow: #ffff00;
$darkYellow: #ffd800;
$lightGrey: #d9d9d9;
$lightestGrey: #eef8ff;
$davyGrey: #555;
$mediumGrey: #888;
$mutedGrey: #979797;
$fadedGrey: #b4c0d3;
$whiteShade: #f7f7f7;
$whiteBlue: #f0f2f500;
$smokeWhite: #f1f1f1;
$fadedWhite: #afc0d7;
$cyan: #007364;
$mediumCyan: #00ccab;
$darkCyan: #000807;
$goldenPoppy: #ffc400;
$yellowGreen: #b5d63d;
$darkYellowGreen: #31313129;
$appHeaderColor: #282c34;
$siderColorBottom: #544b01;
$siderColorTop: #c4bb01;
$subMenuColorBottom: #e4ca00;
$subMenuColorTop: #c4bb01;
$antMenuItemSelectedColor: #b9b000;
$appLogoColor: #2b354a;
$notEnoughDataColor: #ff0000;
$new-track-color: #ff7e7e;
$ready-for-review-track-color: #fdcb93;
$ready-for-tl-track-color: #ddfd93;
$approved-track-color: #93fda1;
$changes-requested-track-color: #f2fd93;
$default-track-color: #fcffe2;
$background-grey: #b4c9db;
$index-white: #f8fafd;
$disabled-grey: #647d9c;
$translucent-black: #00000033;
$off-white: #f4f7f3;
$table-blue: #faffff;
$field-border: #b4b4b4;
$above: #49db7a;
$meets-above: #adce37;
$meets: #e7c61b;
$meets-below: #f3ab81;
$below: #ec8585;
$disabled-background: #ecebeb;
$borderShadow: rgb(0 0 0 / 16%) 0px 1px 2px;
$box-border: #00000029;
$background-transparent: #f5f7f6;
$light-border-grey: #e4e9f0;
$background-light-green: #e5f5ed;
$table-content-preview: #f7f8fb;
$mandatory: #e13317;
$input-borders: #b6c0d1;
$checked-icon: #6bab0f;
$avatar-green: #87d068;
$default-rating: #e8e8e8;
$shadow-border: #f0f0f0;
$ready-for-eval-tag-bg: #f0f5ff;
$ready-for-eval-tag: #adc6ff;
$in-progress-eval-bg: #fffbe6;
$in-progress-eval: #faad14;
$dashboard-graph-green: #bfdfbf;
$dashboard-graph-red: #ffd5c5;
$dashboard-graph-yellow: #f0e68c;
$dashboard-graph-light-green: #bbe4cd;
$dahboard-eval-under-review: #ffae4d;
$dahboard-ready-for-eval-tag: #8c8cff;
$table-highlight: #def7df;
$product-card-heading: #d8fac1;
$parallel-card-track: #c2ded1;
$light-grey-text: #8c8c8c;
$spark: #722ed1;
$spark-background: #f9f0ff;
$spark-border: #d3adf7;
$edge-clr: #eb2f96;
$edge-background: #fff0f6;
$edge-border: #ffadd2;
$active-btn-background: #316577;
$role-btn-inactive-text: #005587;
$choose-btn-bg: #3f8298;
$choose-btn-text-disabled: #cdcdcd;
$count-tag-bg: #007cb0;
$grey-text: #6f6f6f;
$sub-title: #2d3436;
$black-text: #11142d;
$separate-color: #e1e1fb;
$collapse-content-border-color: #dde0eb;
$midnight-grey: #6f7288;
$accessible-blue: #007cb0;
$view-btn-background: #eaf2ee;
$view-btn-text: #3d7a54;
$question-text: #2e3f39;
$wrong-answer: #c10d0d;
$courseContentTagBG: #ddecf0;
$courseLinkIcon: #089bbb;
$dustRed: #ff4d4f;
$header-content-background: #f7f9fc;
$primary-focus: #075f85;
$disabled-icon: #00000040;
$coursePendingIconBg: #ffe7ba;
$scheduleDivider: #ebebeb;
$disabledScheduleDates: #e9e8e8;
$roleSubtitle: #6a7178;
$informationText: #00000073;
$evenTableRow: #bfbfbf;
$pending-tag-bg: #fff1f0;
$pending-tag: #ffa39e;
$chat-drawer-bg: #ceeef3;
$chat-drawer-heading: #00aace;
$chat-bot-text-bg: #e8f0f2;
$chat-bot-text-border: #97bdc5;
$chat-header-border: #c5dcdf;
$poc-table-head: rgba(0, 0, 0, 0.85);
$poc-table-cell: rgba(0, 0, 0, 0.65);
$icon-color: rgba(0, 0, 0, 0.45);
$active-card-bg: rgba(221, 236, 240, 0.5);
$tag-grey-translucent: rgba(187, 218, 225, 0.502);
$loader-ellipsis-dark: #8bb5be;
$loader-ellipsis-medium: #b1cdd3;
$loader-ellipsis-light: #d8e6e9;
$event-duration-text-light: #6b6b6b;
$trainee-dashboard-tag: #b7e7f1;
$first-rank: #71c3dd;
$second-rank: #d3adf7;
$third-rank: #ffd591;
$sunset-orange: #d46b08;
$blue: #096dd9;
$success-green: #007d00;
$primary-9: #00364d;
$steps-subtitle: #595959;
$steps-disabled: #bfbfbf;
$menu-fill: #666;
$lemon-lime-green: #a0d911;
$lemon-lime-green-dark: #7cb305;
$lemon-lime-green-darker: #5b8c00;
$lemon-lime-green-darkest: #3b531a;
$bright-red: #f5222d;
$neutral-black: #434343;
$section-names: #4a484a;
$ready-to-proceed-bg: #fff1b8;
$ready-to-proceed: #d48806;
$in-progress-bg: #d6e4ff;
$completed-bg: rgba(82, 196, 26, 0.3);
$tag-bg-color: #dbdbdb;
$input-disabled-dark: #ececec;
$accent-blue: #dbf0ff;

//Landing page
$landingPageBg: #f7f7fc;

$text-half-white: #cfcfcf;
$transition-0: #c2cfd6;
$transition-100: #f0f3f5;
$kashmirBlue: #536789;
$table-content-preview: #f7f8fb;
$success-text: #49db7a;
$execution-success-text: #527415;
$platinumGrey: #797979;
$silverGrey: #cccccc;
$pending-orange: #e48e0b;
$rejected-red: #cd0f0f;
$draft-black: #323232;
$form-background: #f8f8f8;
$default-background: #f0f2f5;
$table-header: #eaeaea;
$collapse-header: #e1f3f7;
$collapse-header-rejected: #f9d6d7;
$disabled-bg-color: #f5f5f5;
$text-dark-grey: #616161;
$incomplete-background: #fff2f2;
$success-background: #f0f9e1;
$continue-editing-text: #e86f00;
$continue-editing-background: #fef7e6;
$form-disabled-background: #ececec;

$above: rgb(53, 104, 89);
$meets-above: rgb(55, 153, 111);
$meets: rgb(248, 203, 46);
$meets-below: rgb(249, 170, 51);
$below: rgb(253, 85, 35);
$get-started-btn-bg: #1b849b;
$get-started-btn-text-disabled: #b8b8b8;
$review-background: #e8f3f5;
$review-marked-section: #fff3f4;
$active-tag-bg: #f6ffed;
$active-tag-border: #389e0d;
$active-tag-text: #237804;
$inactive-tag-bg: #fff7e6;
$inactive-tag-border: #ffd591;
$inactive-tag-text: #fa8c16;
$selected-table-row: #e6f7ff;
$test-case-pie-pass: #90be6d;
$test-case-pie-fail: #f73d3d;

//bootcamp
$secondary: #153f68;
$tertiary: #7cbfff;

$lightRed: #ff6b83;
$darkRed: #fa423b;
$green: #649d66;
$lightGreen: #87e08a;
$themeGreenMuted: #d6eee3;
$themeGreen: #007364;
$greenYellow: #adff2f;
$greenWhite: #beeedd;
$grey: #979797;
$darkGrey: #0000e6;
$grey32: #525252;

$slateGrey: #708090;
$dullGrey: #a9a9a9;
$lighterGrey: #efeff6;
$transparentGrey: #787f8540;
$textPrimary: #3d405c;
$lightBlue: #a6d2fc;
$hoverButton: #1890ff;
$lightCyanBlue: #b8c2d3;
$kashmirBlue: #536789;
$darkCyanBlue: #0b294e;
$aliceBlue: #f0f8ff;
$dimGrey: #d2d2e4;
$orange: #ff6d00;
$transparent: #00ffff;
$lightBlack: #323232;
$smokeBlack: #0000004d;
$greyShade: #f0f2f5;
$blackShade: #2b354a;
$yellow: #ffff00;
$darkYellow: #ffd800;
$lightGrey: #d9d9d9;
$davyGrey: #555;
$mediumGrey: #888;
$mutedGrey: #979797;
$fadedGrey: #b4c0d3;
$whiteShade: #f7f7f7;
$whiteBlue: #f0f2f500;
$smokeWhite: #f1f1f1;
$fadedWhite: #afc0d7;
$cyan: #007364;
$mediumCyan: #00ccab;
$darkCyan: #000807;
$goldenPoppy: #ffc400;
$yellowGreen: #b5d63d;
$darkYellowGreen: #31313129;
$appHeaderColor: #282c34;
$siderColorBottom: #544b01;
$siderColorTop: #c4bb01;
$subMenuColorBottom: #e4ca00;
$subMenuColorTop: #c4bb01;
$antMenuItemSelectedColor: #b9b000;
$appLogoColor: #2b354a;
$notEnoughDataColor: #ff0000;
$new-track-color: #ff7e7e;
$ready-for-review-track-color: #fdcb93;
$ready-for-tl-track-color: #ddfd93;
$approved-track-color: #93fda1;
$changes-requested-track-color: #f2fd93;
$default-track-color: #fcffe2;
$background-grey: #b4c9db;
$index-white: #f8fafd;
$disabled-grey: #647d9c;
$translucent-black: #00000033;
$off-white: #f4f7f3;
$table-blue: #faffff;
$field-border: #b4b4b4;
$above: #49db7a;
$meets-above: #adce37;
$meets: #e7c61b;
$meets-below: #f3ab81;
$below: #ec8585;
$disabled-background: #ecebeb;
$borderShadow: rgb(0 0 0 / 16%) 0px 1px 2px;
$box-border: #00000029;
$background-transparent: #f5f7f6;
$light-border-grey: #e4e9f0;
$background-light-green: #e5f5ed;
$table-content-preview: #f7f8fb;
$mandatory: #e13317;
$input-borders: #b6c0d1;
$checked-icon: #6bab0f;
$avatar-green: #87d068;
$default-rating: #e8e8e8;
$shadow-border: #f0f0f0;
$ready-for-eval-tag-bg: #f0f5ff;
$ready-for-eval-tag: #adc6ff;
$in-progress-eval-bg: #fffbe6;
$in-progress-eval: #faad14;
$dashboard-graph-green: #bfdfbf;
$dashboard-graph-red: #ffd5c5;
$dashboard-graph-yellow: #f0e68c;
$dashboard-graph-light-green: #bbe4cd;
$dahboard-eval-under-review: #ffae4d;
$dahboard-ready-for-eval-tag: #8c8cff;
$table-highlight: #def7df;
$product-card-heading: #d8fac1;
$parallel-card-track: #c2ded1;
$light-grey-text: #8c8c8c;
$spark: #722ed1;
$spark-background: #f9f0ff;
$spark-border: #d3adf7;
$edge-clr: #eb2f96;
$edge-background: #fff0f6;
$edge-border: #ffadd2;
$active-btn-background: #316577;
$role-btn-inactive-text: #005587;
$choose-btn-bg: #3f8298;
$choose-btn-text-disabled: #cdcdcd;
$count-tag-bg: #007cb0;
$grey-text: #6f6f6f;
$black-text: #11142d;
$separate-color: #e1e1fb;
$collapse-content-border-color: #dde0eb;
$midnight-grey: #6f7288;
$accessible-blue: #007cb0;
$view-btn-background: #eaf2ee;
$view-btn-text: #3d7a54;
$question-text: #2e3f39;
$wrong-answer: #c10d0d;
$courseContentTagBG: #ddecf0;
$courseLinkIcon: #089bbb;
$dustRed: #ff4d4f;
$header-content-background: #f7f9fc;
$disabled-icon: #00000040;
$coursePendingIconBg: #ffe7ba;
$scheduleDivider: #ebebeb;
$disabledScheduleDates: #e9e8e8;
$roleSubtitle: #6a7178;
$informationText: #00000073;
$evenTableRow: #bfbfbf;
$pending-tag-bg: #fff1f0;
$pending-tag: #ffa39e;
$chat-drawer-bg: #ceeef3;
$chat-drawer-heading: #00aace;
$chat-bot-text-bg: #e8f0f2;
$chat-bot-text-border: #97bdc5;
$copy-button-color: #989090;
$copy-button-background: #3d3b3b;
$horizontal-space-line: #dedede;
$logo-box: #c0c3ce;
$message-box-outline: rgba(174, 174, 174, 0.4);
$bot-message-color: rgba(0, 124, 176, 0.6);
$royal-blue: #0068d0;
$light-blue-blur: #2ba1e2;
$dark-bot-message-color: rgba(43, 161, 226, 0.3);
$black-blur-point-1: rgba(0, 0, 0, 0.1);
$user-message-box: rgba(215, 240, 254, 0.6);
$gray-varient-aeaeae: #aeaeae;
$bluish-white: #d7f0fe;
$browninsh-white: #bfbfbe;
$logo-box-color: rgba(157, 212, 207, 0.2);
$question-box: rgba(164, 164, 164, 0.2);
$st-chat-box: #0076a8;
$st-chat-box-color: #c2c2c2;
$st-chat-box-background: #f9f9f9;
$warning-box-st: #4d4d4d;
$prompt-top-color: #ffc300;
$prompt-box-border: #c0c3ce;
$prompt-background-color: #fffae5;
$border-st-box: #4299f7;
$response-box-grey: #bfbfbf;
