@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.segments-container {
    background: $white;
    border-radius: rem(10);
    border: rem(1) solid $box-shadow-bg;
    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
    height: auto;
    padding-bottom: rem(15);

    .segments-header {
        height: auto;
        padding: rem(16);
        border-top-left-radius: rem(10);
        border-top-right-radius: rem(10);

        .segments-header-title {
            font-weight: $fontWeight-medium2;
        }

        span {
            padding-left: rem(12);
        }

        .breadcrumb-divider {
            background-color: $evenTableRow;
            width: calc(100% - 1.5rem);
            margin-left: 0.75rem;
        }
    }

    .segments-content {
        .ant-tabs {
            padding-left: 0.75rem;
        }
    }
}

.ant-tabs-tab-btn {
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-weight: $fontWeight-light1;
}