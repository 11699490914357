@import "./styles/colors";
@import "./Theme/Theme";
@import "./styles/fonts";
@import "./styles/function";

html,
body {
  overflow: hidden;
  .app-container {
    font-family: $fontFamily-regular;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ant-btn {
    border-radius: rem(4);
  }

  .ant-btn-primary {
    background: $primary;
    border: rem(1) solid $primary;
    font-weight: $fontWeight-light1;
  }

  .ant-btn-primary:hover {
    background-color: $primary-focus !important;
    border-color: $primary-focus;
  }

  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    background-color: $primary-focus;
    border-color: $primary-focus;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-secondary[disabled],
  .ant-btn-secondary[disabled]:active,
  .ant-btn-secondary[disabled]:focus,
  .ant-btn-secondary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: $disabled-bg-color;
    border: 1px solid $lightGrey;
  }

  .ant-modal-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: none;
    padding: rem(16);
    gap: rem(16);
  }

  .ant-modal-content {
    height: rem(160);
  }

  // .ant-btn-default,
  // .ant-btn-default:hover,
  // .ant-btn-default:focus,
  // .ant-btn-secondary {
  //   background: $white;
  //   border: rem(1) solid $lightGrey;
  //   color: rgba(0, 0, 0, 0.85);
  // }

  .ant-btn-secondary:hover,
  .ant-btn-secondary:focus {
    border-color: $primary-focus;
    color: $primary-focus;
  }

  .ant-btn-link,
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: $primary;
    font-size: $font-14;
    font-weight: $fontWeight-light1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dot.above-score {
  background-color: $above;
}

.dot.meets-above-score {
  background-color: $meets-above;
}

.dot.meets-score {
  background-color: $meets;
}

.dot.meets-below-score {
  background-color: $meets-below;
}

.dot.below-score {
  background-color: $below;
}

.dot.default-score {
  background-color: $default-rating;
}

//common scss class to be used in every page for header and breadcrumb
.page-header {
  height: rem(106);
  width: 100%;
  background-color: $white;
  padding: rem(20);

  .breadcrumb {
    margin-bottom: rem(10);
  }

  .header-title {
    font-weight: $fontWeight-medium1;
    font-size: $font-19;
  }

  .link-position {
    margin-left: rem(0);
  }
}

.breadcrumb-parent {
  cursor: pointer;
  font-size: rem(16);
  color: $text-grey;
}

.breadcrumb-child {
  cursor: not-allowed;
  font-size: rem(16);
  color: $primary;
  font-weight: $fontWeight-bold;
}
