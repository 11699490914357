@import "../../../styles/colors";
@import "../../../styles/function";
@import "../../../styles/fonts";
@import "../../../Theme/Theme";

.empty-chat-container {
  .warning-box {
    width: 100%;
    border-bottom: 1px solid $light-border-grey;
    background: $white;
    padding: rem(12);
    .note-header {
      font-size: rem(14);
      color: $primary;
      font-family: $fontFamily-medium;
      font-weight: 500;
    }
    .disclaimer {
      letter-spacing: rem(1);
    }

    .warn-box-text {
      color: $steps-subtitle;
      font-size: rem(12);
      font-family: $fontFamily-regular;
      font-weight: 500;
      text-align: justify;
    }
  }
}

.chat-box {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(rem(100));
}

.left-pos-box {
  width: 100%;
  display: flex;
  height: 75%;
  margin-top: 2%;
}

.hi-there-box {
  color: $black;
  border-radius: rem(10);
  background: $white;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: rem(16);
  padding: 1.5rem 1.5rem 1.5rem 4%;
  text-align: left;
  font-size: rem(14);
  font-weight: $fontWeight-light1;
  height: 100%;
  border: $response-box-grey rem(1) solid;
}

.dummy-question-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: 0.4rem;
  flex: 1;
  margin-top: 4%;
  height: 52%;

  .dummy-question-check-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    padding: 1%;
    margin-right: 4%;
    font-size: 0.6rem;
    border-radius: 0.3rem;
    border: rem(1) solid $primary;
    background: $white;
    color: $st-chat-box;
    height: max-content;
    cursor: pointer;

    .check-check {
      padding: 2%;
    }
  }
}
