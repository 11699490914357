@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.stepper-divider {
  height: rem(2.5);
  border-radius: rem(10);
  background-color: $primary;
}
.admin-stepper-title {
  font-size: rem(16);
  font-family: "Open Sans Regular", sans-serif;
}
