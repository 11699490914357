@import "../../styles/colors";
@import "../../Theme/Theme";
@import "../../styles/function";
@import "../../styles/fonts";

.ecourse-card {
  padding: 1.5rem;
  display: grid;
  // gap: rem(24);
  border-radius: rem(10);
  background: var(--neutral-1, $white);
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  // row-gap: 1rem;
  width: 100%;

  &:hover {
    box-shadow: 0 0.188rem 0.825rem rgb(0 0 0 / 0.2);
    cursor: pointer;
  }

  .view-certificate {
    color: $primary;
  }
}

.ecourse-title {
  color: var(--neutral-13, $black);
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(29.22);
  font-style: normal;
  font-weight: 600;
  margin-top: 1%;
  margin-bottom: 1rem;
}

.ecourse-content {
  color: var(--neutral-13, $black);
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24);
  width: 100%;

  .ecourse-duration {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-family: "Open Sans Regular", sans-serif;
  }
}

.ecourse-duration-container {
  align-items: flex-start;
  display: flex;
  margin-top: rem(15);

  .ecourse-duration {
    display: flex;

    justify-content: center;
    gap: 1rem;
    font-family: "Open Sans Regular", sans-serif;
  }
}

.bottom {
  margin-top: 4%;
}

.ecourse-text {
  color: var(--neutral-13, $black);
  /* H5/regular */
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24);
  /* 150% */
  text-align: justify;
  margin-top: rem(-15);
}

.header-progress {
  color: var(--neutral-8, $steps-subtitle);
  /* Body/regular */
  font-family: "Open Sans Regular", sans-serif;
  font-size: rem(14);
  font-style: normal;
  font-weight: 400;
  line-height: rem(22);
  /* 157.143% */
}

.eprogress-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.ecourse-image-container {
  background: $imgLoadingBackround;
  animation: img-loading 1s linear infinite alternate;
}

.rcourse-image {
  border-radius: rem(8);
  object-fit: cover;
  max-width: rem(250);
  width: rem(250);
  height: 12.75rem;
}

@media (max-width: rem(1200)) {
  .ecourse-card {
    width: 100%;
    padding: 1.5rem;
    display: grid;
    // gap: rem(24);
    border-radius: rem(10);
    background: var(--neutral-1, $white);
    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
    // row-gap: 1rem;

    &:hover {
      box-shadow: 0 0.188rem 0.825rem rgb(0 0 0 / 0.2);
      cursor: pointer;
    }
  }
  .ecourse-title {
    color: var(--neutral-13, $black);
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(25);
    font-style: normal;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 1rem;
  }
  .rcourse-image {
    border-radius: rem(8);
    object-fit: cover;
    max-width: rem(200);
    width: rem(200);
    height: 12.75rem;
  }
  .ecourse-content {
    color: var(--neutral-13, $black);

    /* H5/regular */
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
    /* 150% */
    // width: 67%;
    width: 100%;
  }

  .ecourse-text {
    // max-height: 20%;
    // overflow: hidden;
    color: var(--neutral-13, $black);

    /* H5/regular */
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
    /* 150% */
    text-align: justify;
  }

  .header-progress {
    color: var(--neutral-8, $steps-subtitle);
    /* Body/regular */
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(18);
    /* 157.143% */
  }
}
