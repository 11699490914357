@import "../../Theme/Theme";
@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/function";

.header-wrapper {
  .product-plugin {
    display: flex;
    align-items: center;
    gap: rem(34);

    .description {
      width: max-content;
      padding-top: rem(4);

      .role {
        font-weight: $fontWeight-light1;
        font-size: $font-12;
        line-height: rem(15);
        color: $event-duration-text-light;
      }

      .bootcamp {
        font-size: $font-16;
        font-weight: $fontWeight-medium1;
        line-height: rem(20);
      }
    }

    .anticon-down svg {
      width: rem(10);
      height: rem(10);
      font-size: $font-18;
    }

    .change-role-icon {
      cursor: pointer;
      svg {
        color: $blackGrey;
        font-size: $font-12;
        width: rem(25);
        height: rem(25);
      }
    }

    .not-allowed {
      cursor: not-allowed;
    }
  }
}
.header {
  .enabled-button {
    background: $white !important;
    color: $accessible-blue !important;
    border-radius: rem(4);
    &:hover {
      background: $courseContentTagBG !important;
    }
  }
}
.header-search {
  width: 40%;
}
.header-title {
  color: var(--primary-9, $primary-9);

  /* H4/medium */
  // font-family: Open Sans;
  font-size: rem(20);
  font-style: normal;
  font-weight: 600;
  line-height: rem(28); /* 140% */
  margin-left: 1rem;
}
.header-container-nav {
  height: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.header-container {
  height: 100%;
  padding-left: 1%;
  padding-right: 1%;
}

.header-tnl {
  display: flex;
}
