@import "../../../styles/colors";
@import "../../../styles/function";
@import "../../../styles/fonts";
@import "../../../Theme/Theme";

.question-box_container {
  padding: rem(8) rem(14);
  width: 100%;
  border-radius: rem(0) rem(0) rem(10) rem(10);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  .input-box {
    width: 100%;
    height: rem(37);
    display: flex;
    align-items: flex-start;
    border-radius: 0.3rem;
    border: rem(1) solid $primary;
    padding-right: 1rem;
    padding-left: rem(5);
    background-color: $white;
    font-size: rem(12);
    margin: rem(7) rem(0) rem(6) rem(0);

    .input-field-1 {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      font-weight: $fontWeight-bolder;

      input {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        padding: rem(12);

        :focus {
          border: none;
          outline: none;
        }
      }

      .input-field__input-1 {
        height: 100%;
        width: 97%;
        font-size: 1rem;
        outline: none;
        border: none;
        background-color: transparent;
      }

      .input-field__button {
        height: 100%;
        width: 5%;
        margin-right: 0.2rem;
        font-size: 1rem;
        border: none;
        border-radius: rem(4);
        cursor: pointer;
        background-color: transparent;
      }
      .input-icon {
        color: $primary;
      }
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
  }
}
