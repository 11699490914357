@import "../../../styles/colors";
@import "../../../styles/function";
@import "../../../styles/fonts";
@import "../../../Theme/Theme";

.prompt-box {
  width: 100%;
  border-top: rem(1) solid $prompt-box-border;
  background-color: $white;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .prompt-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $grey-text;

    .prompt-top-first {
      display: flex;
      font-weight: $fontWeight-medium2;
      padding-left: 1%;
      font-size: rem(12);
      align-items: center;
      gap: 0.6rem;
    }

    .prompt-top-second {
      display: flex;
      padding-right: 1rem;
      font-size: 0.6rem;
      align-items: center;
      font-weight: $fontWeight-medium2;
      gap: 0.6rem;
    }
  }

  .prompt-bottom {
    margin-right: 1%;
    display: flex;
    align-items: center;
    width: 98%;
    justify-content: end;
    font-size: 0.8rem;
    font-weight: $fontWeight-medium;

    .prompt-question-text {
      text-align: start;
      padding-left: rem(5);
      width: 100%;
    }
  }

  .question_prompt-text {
    font-family: $fontFamily-regular;
    font-size: rem(12);
    font-style: normal;
    font-weight: $fontWeight-light1;
    line-height: rem(20);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: rem(6);
    border-bottom-right-radius: rem(0);
    background: $collapseHeader;
    border: rem(1) solid $primary;
    height: fit-content;
  }

  .cursor-p {
    cursor: pointer;
  }
  .cursor-n {
    cursor: not-allowed;
  }

  .question-icon-boldness {
    font-size: rem(9);
    font-weight: $fontWeight-bold;
    color: $primary;
  }
}
