@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.course-content-container {
  .divider {
    background-color: $primary;
  }

  .course-content-heading-wrapper {
    margin-bottom: rem(9);

    .course-content-heading {
      font-family: "Open Sans Regular", sans-serif;
      font-weight: $fontWeight-medium2;
    }
  }

  .course-content {
    // margin-top: rem(-9);
    .floating-buttons {
      cursor: pointer;
      flex: 0;
    }

    .ant-row {
      justify-content: space-between;
    }

    .course-media-container {
      box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
      background: $white;
      padding: 0 !important;
      border-radius: rem(10);
      height: $chat-bot-height;

      .assessment-content-container {
        &::-webkit-scrollbar {
          width: rem(5);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: rem(5);
          background-color: $text-grey;
        }
      }

      // overflow: auto;
      // width: 65.66%;
      // flex: 0 0 65.66%;

      .pdf-container {
        width: 100%;
        height: calc($chat-bot-height - rem(90) - rem(48));
        padding: rem(16);
      }

      .pdf-container-certificate {
        width: 100%;
        height: calc($chat-bot-height - rem(170));
        padding: rem(16);
      }

      .pdf-toolbar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: rem(16);
        height: rem(48);
        padding-bottom: rem(8);
      }

      .not-allowed-span {
        cursor: not-allowed;
        border: none;
      }

      .allowed-span {
        cursor: pointer;
        border: none;
      }

      .course-content-header {
        span {
          color: $primary;
        }

        height: auto;
        padding: rem(16);
        background: $accent-blue;
        border-top-left-radius: rem(10);
        border-top-right-radius: rem(10);

        .course-header {
          font-weight: $fontWeight-medium1;
          font-size: rem(18);
          display: block;
        }

        .course-title {
          color: $black-text;
          font-family: "Open Sans Regular", sans-serif;
          font-weight: $fontWeight-light1;
          font-size: rem(16);
        }

        .content-left {
          text-align: left;
          cursor: pointer;
          .ant-typography {
            margin-bottom: 0 !important;
          }
        }

        .content-right {
          margin-top: 0 !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          .caret-icon {
            display: flex;
            align-items: center;
          }
        }

        .content-right img:last-child {
          margin-left: rem(30);
        }
        .content-right img:first-child {
          margin-right: rem(30);
        }
        .exit-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .exit-icon > img {
          transform: scale(1.2);
          cursor: pointer;
        }
      }

      .badge-container {
        text-align: center;
        padding: rem(50);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .badge-image {
          width: 30%;
          height: 30%;
        }

        .badge-heading {
          font-size: rem(40) !important;
          margin-bottom: rem(34);
        }

        .badge-title {
          font-size: $font-40;
          font-weight: $fontWeight-medium2;
          color: $primary;
        }

        .badge-paragraph {
          width: 70%;
          margin-top: 0;
          font-size: rem(24);
          font-family: "Open Sans Regular", sans-serif;
        }

        .para-body {
          color: $primary;
          margin-top: rem(20);
          font-weight: $fontWeight-medium2;
        }

        .badge-button {
          padding: rem(14);
          background-color: $primary;
          color: $white;
          border: none;
        }
      }

      .certificate-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: rem(19);
        align-items: center;

        .content-left {
          text-align: left;
          cursor: pointer;
          .ant-typography {
            margin-bottom: 0 !important;
          }

          .course-header {
            font-weight: $fontWeight-medium1;
            font-size: rem(18);
            display: block;
          }
        }

        .content-right {
          text-align: right;
          cursor: pointer;

          .certificate-button {
            padding: rem(14);
            background-color: $primary;
            color: $white;
            border: none;
          }
        }
      }

      .launch-quiz-container {
        text-align: center;
        padding: rem(50);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3.5rem;

        .quiz-image {
          width: auto;
          height: auto;
        }

        .quiz-heading {
          font-size: rem(20) !important;
        }

        .quiz-paragraph {
          width: 80%;
          margin-top: 0;
        }

        .quiz-title {
          font-size: $font-22;
          font-weight: $fontWeight-bold;
          color: $primary;
        }

        .quiz-button {
          padding: rem(14);
          background-color: $primary;
          color: $white;
          border: none;
        }
      }
    }

    .chat-bot-container {
      padding: 0 !important;
    }

    .ant-card.ant-card-bordered {
      border-radius: 0;
    }

    .course-content-info-card {
      background-color: $white;

      .heading {
        font-size: $font-20;
        font-weight: $fontWeight-medium1;
        color: $black-text;
      }

      .divider {
        margin: 0 0 rem(16) 0;
        border-color: $separate-color;
      }
    }

    .course-ended {
      width: 100%;
      text-align: center;
      margin-block-end: rem(0);
    }
  }

  .padded-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(18) rem(20);
  }

  .content-button {
    font-size: rem(16);
    font-weight: $fontWeight-light1;
    font-family: "Open Sans Regular", sans-serif;
  }

  .section-title:hover {
    cursor: pointer;
    // background: $table-content-preview;
  }

  .course-tabs {
    .ant-tabs {
      border-radius: rem(10);
      box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
      background: $white;
    }

    .spin-container {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-nav {
      border-top-right-radius: rem(10);
      border-top-left-radius: rem(10);
      background: $accent-blue;
      margin: 0;
      height: 5.54rem;
      padding: rem(16);
      margin-bottom: 0 !important;

      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          font-size: rem(20);
          color: $primary;
        }
      }

      .ant-tabs-tab-active {
        font-weight: 600;
      }

      .ant-tabs-ink-bar {
        background: $primary;
      }
    }

    .ant-tabs-content {
      padding: rem(16);
    }
  }
}

.loading-spinner {
  width: 100%;
  height: calc(100vh - rem(64));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(16);
}

.pdf-loading-spinner {
  width: 100%;
  height: calc(70vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(16);
}

.ant-popover-content:has(.course-module-popup) {
  margin-right: 2.8rem !important;
  margin-top: 0.8rem !important;
}

.course-module-popup {
  // min-width: calc(100vw - rem(20) - 70vw);
  // max-width: 25vw;
  box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
  background: $white;
  padding: 0 !important;
  height: $chat-bot-height;
  // margin-left: 1.5rem;
  border-radius: 0.625rem;

  .course-module-header {
    border-radius: rem(6) rem(7) rem(6) rem(6);
    background: var(--neutral-2, #f2f2f2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: rem(16);
    padding-right: 0;

    .course-content-popup-header {
      font-family: "Open Sans Regular", sans-serif;
      font-weight: $fontWeight-medium2;
    }

    .header-5 {
      color: $primary;
      font-size: rem(20);
    }

    .ant-btn-text {
      font-size: rem(16);
    }
  }

  .module-popup-collapse-container {
    background: none;
    border-radius: 0;
    border: none;
    max-height: calc($chat-bot-height - 3.5rem);
    overflow-y: auto;
    padding-bottom: rem(12);

    &::-webkit-scrollbar {
      width: rem(5);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rem(5);
      background-color: $text-grey;
    }

    .ant-collapse-item,
    .ant-collapse-content {
      border: none;
    }

    .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ant-collapse-header {
      padding-bottom: 0;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-size: rem(12);
      font-weight: 400;

      .header-title {
        font-size: rem(14);
        font-weight: 600;
        margin-left: 0;
        font-family: "Open Sans Regular", sans-serif;
      }

      .header-info {
        color: #595959;
        font-size: rem(12);
        font-weight: 400;
        font-family: "Open Sans Regular", sans-serif;
      }
    }

    .badges {
      margin-left: rem(15);
    }

    .section-container {
      > div {
        display: flex;
        flex-direction: column;
        align-items: start;
        font-size: rem(12);
        padding: rem(8) rem(0);
        gap: rem(4);
        border-bottom: 1px solid #bfbfbf;

        .section-title {
          font-size: rem(14);
          color: $blackGrey;
          font-family: "Open Sans Regular", sans-serif;
        }

        .section-info {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $steps-subtitle;

          span {
            font-family: "Open Sans Regular", sans-serif;
            font-size: rem(14);
          }
        }
      }

      .section-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    div.section-container:hover {
      cursor: pointer;
      background: #a6a6a65a;
    }

    div.section-container:last-child {
      > div {
        border: none;
      }
    }
  }
}

.ant-popover-content:has(.course-module-popup) {
  margin-right: rem(30);
  margin-top: rem(20);

  .ant-popover-inner {
    padding: 0;
    border-radius: rem(10);
    overflow: hidden;
    box-shadow: rem(0) rem(0) rem(8) rem(0) $box-shadow-bg;
  }
}
.assessment-header {
  background: $accent-blue;
}

.quiz-status {
  height: 85vh;
  // overflow: auto;
  // &::-webkit-scrollbar {
  //   width: rem(5);
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: rem(5);
  //   background-color: $text-grey;
  // }
  .content {
    color: $primary;
    font-weight: $fontWeight-medium2;
    font-size: rem(16);
  }

  .quiz-question-title {
    font-family: "Open Sans Regular", sans-serif;
  }
  .answered-status {
    display: flex;
    align-items: center;
    font-family: "Open Sans Regular", sans-serif;
    justify-content: center;
  }
  .completion-status-container {
    .quiz-status-content {
      cursor: pointer;
      padding: rem(16);
      padding-bottom: rem(4);

      .quiz-status-card {
        background: $statusCard;
      }
    }
    overflow: auto;
    height: 77vh;
    &::-webkit-scrollbar {
      width: rem(5);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rem(5);
      background-color: $text-grey;
    }
  }
}

//temp

.summary-container {
  border-radius: 0.625rem;

  .course-content-header {
    span {
      color: $primary;
    }

    height: auto;
    padding: rem(16);
    background: $accent-blue;
    border-top-left-radius: rem(10);
    border-top-right-radius: rem(10);

    .course-header {
      font-weight: $fontWeight-medium1;
      font-size: rem(18);
      display: block;
    }

    .course-title {
      color: $black-text;
      font-family: "Open Sans Regular", sans-serif;
      font-weight: $fontWeight-light1;
      font-size: rem(16);
    }

    .content-left {
      text-align: left;
      cursor: pointer;
    }

    .content-right {
      text-align: right;
      margin-top: rem(15);
      cursor: pointer;
    }

    .content-right img:last-child {
      margin-left: rem(30);
    }
  }
  .summary-content {
    box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.1490196078);
    background: #fff;
    padding: rem(16);
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    .current-summary-content {
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(16);
    }
  }
}
