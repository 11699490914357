@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.section-details-tile-row {
  .section-details-tile-buttons {
    display: none;
  }

  .section-details-tile-heading {
    color: #0f59b0;
    font-size: rem(16);
    font-weight: $fontWeight-medium2;
    line-height: rem(24);
    font-family: "Open Sans Regular", sans-serif;
  }

  .section-details-tile-title {
    color: black;
    font-size: rem(16);
    font-weight: $fontWeight-normal;
    line-height: rem(24);
    font-family: "Open Sans Regular", sans-serif;
  }
}

.section-details-tile-row:hover {
  .section-details-tile-buttons {
    display: block;
    cursor: pointer;
    font-size: rem(20);
  }

  .section-details-tile-buttons > span {
    color: var(--Primary-Blue, $primary);
    text-align: center;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
  }
}
