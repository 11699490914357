@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.course-details-container {
  .breadcrumb-divider {
    background-color: $primary;
  }

  @keyframes img-loading {
    0% {
      background-color: $transition-0;
    }

    100% {
      background-color: $transition-100;
    }
  }

  .course-details-card {
    background: $white;
    box-shadow: 0px 2px 8px 0px $box-shadow-bg;
    border-radius: 10px;
    border: none;
    padding: rem(24) rem(16);
    height: 100%;

    .ant-card-body {
      padding: 0;
    }

    .img-container {
      height: rem(400);
      width: 100%;
      margin-top: rem(16);
      padding: rem(0);
      border-radius: rem(4);
      background: $imgLoadingBackround;
      animation: img-loading 5s linear infinite alternate;

      .loading-spinner-course-det {
        width: 100%;
        height: rem(400);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(16);
      }

      img {
        width: 100%;
        height: 100%;

        border-radius: rem(8);
        object-fit: cover;
      }
    }

    .progress-wrapper .progress-bar {
      width: rem(50);
      height: rem(50);
    }

    .progress-wrapper .info-deadline {
      color: $text-grey;

      span {
        font-weight: bold;
      }
    }

    .info {
      font-size: $font-14;
      font-weight: $fontWeight-light1;
      color: $black-text;
      display: flex;
      align-items: center;
    }

    .enroll-btn {
      width: 100%;
      height: rem(42);
      font-size: $font-14;
    }

    .enrolled {
      color: $white;
      border-color: $text-grey;
      background-color: $text-grey;
    }

    .detail-buttons {
      .ant-btn {
        width: 100%;
        height: rem(42);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .course-unenroll {
        border: rem(2) solid $primary;
        color: $primary;
      }

      .course-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: rem(1) solid;
      }
    }

    .divider {
      border-color: $dullGrey;
    }
  }

  .anticon svg {
    height: rem(24);
    width: rem(24);
  }

  pre {
    font-family: $fontFamily-medium;
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
  }

  .github-icon svg {
    width: rem(50);
    height: rem(35);
  }

  ::-webkit-scrollbar {
    width: rem(5);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: rem(5);
    background-color: $text-grey;
  }

  .seats-left-span {
    font-weight: bold;
  }
}

.ant-modal-root {
  .ant-modal {
    top: 40%;
  }

  .ant-modal-close-icon {
    margin-top: rem(5);
  }

  .ant-btn-default {
    margin-right: rem(-12);
  }
}
