@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.preview-container {
  background: $white;
  border-radius: rem(10);
  border: rem(1) solid $box-shadow-bg;
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  height: auto;
  padding-bottom: rem(15);

  .preview-header {
    height: auto;
    padding: rem(16);
    border-top-left-radius: rem(10);
    border-top-right-radius: rem(10);
    padding-left: rem(25) !important;
    padding-right: rem(25) !important;

    .preview-header-title {
      font-weight: $fontWeight-medium2;
    }

    span {
      padding-left: rem(12);
    }

    .breadcrumb-divider {
      background-color: $evenTableRow;
    }
  }

  .preview-content {
    padding-left: rem(25) !important;
    padding-right: rem(25) !important;

    .preview-section-details-tile {
      background: $white;
      border-radius: rem(10);
      border: rem(1) solid $box-shadow-bg;
      height: auto;
      padding-bottom: rem(15);
      cursor: move;
      .ant-collapse-header {
        cursor: move !important;
      }

      .ant-collapse {
        background: white !important;
      }

      .preview-section-tile-container {
        margin: auto;
        justify-content: center;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .preview-add-section-tile {
          border-radius: 6px;
          border: 1px solid var(--neutral-6, $evenTableRow);
          background: var(--neutral-1, $white);

          .ant-col {
            padding-left: 0 !important;
          }

          .form-add-section-btn-col-p {
            padding-right: 0 !important;
            margin-top: 3rem;
            margin-bottom: 1rem;

            .form-add-section-btn-col {
              padding-right: 0 !important;

              .form-add-section-button {
                border-radius: 0%;
                background-color: $primary;
                color: $white;
                margin: 0;
              }
            }
          }
        }
      }

      .lesson-tile-container {
        margin: auto;
        justify-content: center;

        .add-lesson-tile-wrapper {
          padding-left: rem(10) !important;
          padding-right: rem(10) !important;
        }

        .add-lesson-tile {
          border-radius: 6px;
          border: 1px solid var(--neutral-6, $evenTableRow);
          background: var(--neutral-1, $white);

          .ant-col {
            padding-left: 0 !important;
          }

          .label {
            font-size: rem(14);
            font-family: "Open Sans Regular", sans-serif;
          }

          .form-add-lesson-btn-col-p {
            padding-right: 0 !important;
            margin-top: 3rem;
            margin-bottom: 1rem;

            .form-add-lesson-btn-col {
              padding-right: 0 !important;

              .form-add-lesson-button {
                border-radius: 0%;
                background-color: $white;
                color: $primary;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.preview-button-container {
  background: $white;
  border-radius: rem(10);
  border: rem(1) solid $box-shadow-bg;
  box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
  height: auto;

  .form-next-button {
    border-radius: 0%;
    background-color: $primary;
    color: $white;
  }

  .form-clear-button {
    border-radius: 0%;
    background-color: $white;
    color: $primary;
  }
}

// .preview-section-details-tile:hover {

// }
.dragging {
  border: rem(1) solid $primary;
}
