@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.quiz-status {
  background: $white;
  box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
  border-radius: rem(10);
  border: none;
}
.quiz-flow-status {
  &::-webkit-scrollbar {
    width: rem(5);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(5);
    background-color: $text-grey;
  }
}

.quiz-status-header {
  height: auto;
  padding: rem(16);
  padding-left: rem(30);
  padding-right: rem(40);
  background: $accent-blue;
  border-top-left-radius: rem(10);
  border-top-right-radius: rem(10);

  .title {
    .content {
      color: $primary;
      font-weight: $fontWeight-medium2;
      font-size: rem(16);
    }
  }
}

.quiz-container {
  // padding: rem(16);
  width: 100%;

  .header-container {
    margin-bottom: rem(-20);

    .quiz-heading {
      font-family: "Open Sans Regular", sans-serif;
      font-size: rem(24);
      font-weight: $fontWeight-medium2;
    }
  }

  .divider {
    background-color: $primary;
  }

  .quiz-status {
    padding: 0 !important;

    .quiz-status-content:last-child {
      margin-bottom: rem(10);
    }

    .quiz-status-content {
      padding: rem(16);
      text-align: center;
      margin-bottom: rem(-20);
      overflow: auto;
      width: 100%;

      .score-card {
        background: $statusCard;
      }

      .score-card-question,
      .score-card-mark {
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(14);
        font-weight: $fontWeight-light1;
      }

      .quiz-status-card {
        background: $statusCard;

        .answer-status {
          font-size: rem(16);
          font-family: "Open Sans Regular", sans-serif;
          font-weight: $fontWeight-light1;
        }

        .quiz-question-title,
        .mark-status {
          font-family: "Open Sans Regular", sans-serif;
          font-size: rem(14);
          font-weight: $fontWeight-light1;
        }
      }
    }
  }

  .quiz-main-section {
    .quiz-main-header {
      display: flex;
      justify-content: space-between;

      .content-right {
        justify-content: center;
        margin-top: 0 !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .caret-icon {
          display: flex;
          align-items: center;
        }
      }
      .exit-icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1rem;
      }
      .exit-icon > img {
        transform: scale(1.2);
        cursor: pointer;
      }

      .content-right img:last-child {
        margin-left: rem(30);
      }
      .content-right img:first-child {
        margin-right: rem(30);
      }

      span {
        font-weight: $fontWeight-medium2;
      }

      .quiz-main-header-question-indicator {
        text-align: center;
        color: $grey;

        span {
          font-family: "Open Sans Regular", sans-serif;
          font-weight: $fontWeight-light1;
          font-size: rem(14);
        }
      }
    }

    .quiz-next-button {
      border-radius: 0%;
      background-color: $primary;
      color: $white;
      font-family: "Open Sans Regular", sans-serif;
      font-weight: $fontWeight-light1;
      font-size: rem(16);
    }

    .quiz-back-button {
      border-radius: 0%;
      background-color: $white;
      color: $primary;
      font-family: "Open Sans Regular", sans-serif;
      font-weight: $fontWeight-light1;
      font-size: rem(16);
    }

    .quiz-question-section .ant-col:last-child {
      min-height: 4rem;
    }

    .quiz-question-section {
      .item-1 {
        color: $red;
      }

      .mark-content {
        font-family: "Open Sans Regular", sans-serif;
        font-size: rem(14);
        font-weight: $fontWeight-medium2;
      }

      .item-2 {
        color: $primary;
      }

      .answered-status {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        span {
          font-family: "Open Sans Regular", sans-serif;
          font-size: rem(16);
          font-weight: $fontWeight-light1;
        }
      }
    }

    .quiz-question-section-question {
      .question-content {
        font-size: rem(16);
        font-family: "Open Sans Regular", sans-serif;
        font-weight: $fontWeight-medium2;
      }
    }

    .quiz-question-section-image {
      margin-right: rem(10);
    }

    .quiz-question-section .ant-col:nth-child(2) {
      color: $primary;
      font-weight: $fontWeight-medium2;
    }

    .quiz-question-section .ant-col:last-child {
      position: relative;
    }

    .quiz-question-section .ant-col:last-child {
      div:first-child {
        position: absolute;
        top: 0;
        right: 0;
        color: $black;
        font-weight: $fontWeight-bold;
      }

      div:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .radio-item,
    .radio-item-correct,
    .radio-item-incorrect .ant-radio-wrapper {
      width: 100% !important;
      padding: 0.9375rem !important;
    }

    .radio-item,
    .checkbox-item {
      // padding: rem(15);
      padding-left: rem(30);
      margin-bottom: rem(10);
      background: $white;
      border-radius: rem(10);
      border: rem(1) solid $box-shadow-bg;
      height: auto;
    }

    .radio-item-correct,
    .checkbox-item-correct {
      // padding: rem(15);
      margin-bottom: rem(10);
      background: $white;
      border-radius: rem(10);
      border: rem(2) solid $correct-green;
      border-left: rem(16) solid $correct-green;
      height: auto;
    }

    .radio-item-incorrect,
    .checkbox-item-incorrect {
      // padding: rem(15);
      margin-bottom: rem(10);
      background: $white;
      border-radius: rem(10);
      border: rem(2) solid $dustRed;
      border-left: rem(16) solid $dustRed;
      height: auto;
    }

    .ant-radio-group,
    .ant-checkbox-group {
      margin-top: rem(20);
    }
  }

  .quiz-explanation-text {
    text-align: justify;
    font-family: "Open Sans Regular", sans-serif;
    font-size: rem(16);
    font-weight: $fontWeight-light1;
  }
}

.quiz-container .quiz-main-section-wrapper {
  padding: 0 !important;

  .ant-col-lg-15 {
    flex: 0 0 63.5%;
    max-width: 63.5%;
  }
}

.ant-modal-close-icon {
  margin-top: rem(5);
}

.width-100 {
  width: 100%;
}
.loading-spinner {
  width: 100%;
  height: calc(100vh - rem(64));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(16);
}
