@import "./fonts";
/*
This mixin will create utility classes for spacings for use in your web projects.
usage : property(m-margin,p-padding)side("",t,b,l,r)-breakpoint("",xs,sm,md,lg,xl)-value(multiple of 4)
for expample 
.mt-4 {
  margin-top: 0.25 rem; }

.pt-4 {
  padding-top: 0.25 rem; }

.mt-xs-4 {
  margin-top: 0.25 rem; }

.pt-xs-4 {
  padding-top: 0.25 rem; } 

.mx-32 {
  margin-left: 2 rem;
  margin-right: 2 rem;
}

.px-32 {
  padding-left: 2 rem;
  padding-right: 2 rem;
}

.my-32 {
  margin-top: 2 rem;
  margin-bottom: 2 rem;
}

.py-32 {
  padding-top: 2 rem;
  padding-bottom: 2 rem;
}

NOTE: 
1) the spacings right now is multiple of 2 to support use of rem 
   the class .mt-0 or .mt-2 will work but .mt-1, .mt-3 will not work
2) this mixin only supports padding and margin till 32px or 2 rem for now

*/

$sides: (
  "": "",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
  "x": "x",
  "y": "y",
);

$breakpoints: (
  "": "",
  "xs": $break-xs,
  "sm": $break-sm,
  "md": $break-md,
  "lg": $break-lg,
  "xl": $break-xl,
);

$spacings: (
  0,
  2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  42,
  44,
  46,
  48,
  50,
  52,
  54,
  56,
  58,
  60
); // multiples of 2 to support ems or rems

@mixin acrossAxisSpacings($sideName, $selector, $property, $space) {
  @if $sideName == "x" {
    .m#{$selector} {
      margin-left: #{$space}rem;
      margin-right: #{$space}rem;
    }

    .p#{$selector} {
      padding-left: #{$space}rem;
      padding-right: #{$space}rem;
    }
  } @else {
    .m#{$selector} {
      margin-top: #{$space}rem;
      margin-bottom: #{$space}rem;
    }

    .p#{$selector} {
      padding-top: #{$space}rem;
      padding-bottom: #{$space}rem;
    }
  }
}

@mixin generateSpacings {
  @each $breakName, $breakValue in $breakpoints {
    @each $sideName, $sideValue in $sides {
      @each $i in $spacings {
        $property: "";
        $space: calc($i / 16); // convert the space into rem
        $selector: "";

        @if $sideName == "" {
          $property: "";
        } @else {
          $property: -#{$sideValue};
        }

        @if $breakName != "" {
          $selector: #{$sideName}-#{$breakName}-#{$i};
        } @else {
          $selector: #{$sideName}-#{$i};
        }

        @if $breakName != "" {
          @media (min-width: $breakValue) {
            @if $sideName == "x" or $sideName == "y" {
              @include acrossAxisSpacings(
                $sideName,
                $selector,
                $property,
                $space
              );
            } @else {
              .m#{$selector} {
                margin#{$property}: #{$space}rem;
              }

              .p#{$selector} {
                padding#{$property}: #{$space}rem;
              }
            }
          }
        } @else {
          @if $sideName == "x" or $sideName == "y" {
            @include acrossAxisSpacings(
              $sideName,
              $selector,
              $property,
              $space
            );
          } @else {
            .m#{$selector} {
              margin#{$property}: #{$space}rem;
            }

            .p#{$selector} {
              padding#{$property}: #{$space}rem;
            }
          }
        }
      }
    }
  }
}

.m-auto {
  margin: auto;
}

.m-unset {
  margin: unset;
}

.p-unset {
  padding: unset;
}

@include generateSpacings();
