@import "../../styles/colors";
@import "../../styles/function";
@import "../../styles/fonts";
@import "../../Theme/Theme";

.st_wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $chat-bot-height;
  min-height: $chat-bot-height;
  max-height: $chat-bot-height;
  overflow: hidden;
  // margin-left: rem(24);
  border-radius: rem(10);
  box-shadow: rem(0) rem(2) rem(8) rem(0) $box-shadow-bg;
}

.smart_tutor-container {
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .chat-message-box {
    height: 100%;
    display: flex;
    flex-direction: column;

    .spin-container {
      display: flex;
      justify-content: center;
    }

    .chat-content-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: rem(5);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: rem(5);
        background-color: $text-grey;
      }
    }
  }

  .smart-tutor-sub-box {
    width: 67%;
    border-radius: rem(2);
    border: rem(1) solid $logo-box;
    display: flex;
    flex-direction: column;
    min-height: 55%;

    .logo-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      height: 12vh;
      border-radius: 0.75rem;
      border: rem(1) solid $logo-box;
      background: $logo-box-color;

      .logo-text-style {
        font-family: $fontFamily-regular;

        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
      }

      .image-sizing {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .up-arrow-box {
      width: 100%;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content-1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: center;
      gap: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
    }

    .inside-text-1 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center;
      font-family: $fontFamily-regular;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $fontWeight-light1;
      line-height: 1.5rem;
    }

    .space-line {
      width: 3rem;
      height: 0.05rem;
      background-color: $horizontal-space-line;

      display: flex;
      justify-content: center;
    }

    .down-arrow-box {
      width: 100%;
      height: 2.5rem;
      border-radius: 0rem 0rem 0.75rem 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .copy_button_styling {
    position: absolute;
    top: rem(7.5);
    right: rem(6);
    cursor: pointer;
    outline: none;
    background: none;
    color: $white;
    width: 3.8rem;
    border: rem(0.2) solid $copy-button-color;
    border-radius: rem(2);
    background-color: $copy-button-background;
    height: 1.5rem;
    font-weight: $fontWeight-light1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .regenerate-button-holder {
    display: flex;
    justify-content: center;
    color: $black;

    .regenerate-button {
      padding: rem(8);
      border: rem(1) solid $browninsh-white;
      border-radius: rem(8);
      transition: ease 0.25s all;
      text-align: left;
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;
      font-size: 0.7rem;
    }

    .cursor-p {
      cursor: pointer;
    }

    .cursor-n {
      cursor: not-allowed;
    }

    .regenerate-button span {
      padding-left: rem(4);
      padding-right: rem(6);
    }
  }

  .message_box-container {
    width: 100%;
    padding: 0rem 0rem 0rem rem(15);
    position: relative;
    display: flex;
    height: auto;
    flex-direction: column;
    margin-top: 2.5%;
    margin-bottom: 2.5%;

    .response_message-content {
      margin-bottom: rem(0);

      .hljs {
        &::-webkit-scrollbar {
          height: rem(5);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: rem(5);
          background-color: $text-grey;
        }
      }
    }

    .response_message-box {
      width: 100%;
      font-size: rem(12);
    }

    .message_box {
      max-width: 97%;
      flex-shrink: 0;
      // box-shadow: rem(1) rem(1) rem(5) rem(0) $black-blur-point-1;
      color: $black;
      /* body */
      font-family: $fontFamily-regular;
      font-size: rem(14);
      font-style: normal;
      font-weight: $fontWeight-light1;
      line-height: rem(20);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;

      .sources-box {
        padding: rem(10);
        padding-top: rem(18);
        margin-left: rem(10);
        padding-left: rem(0);
        text-align: justify;
        width: 97%;
        border-top: rem(1) solid $bot-message-color;

        .sources-text {
          color: $black;
          text-align: justify;
          font-family: $fontFamily-regular;
          font-size: 0.75rem;
          font-style: italic;
          font-weight: $fontWeight-light1;
          line-height: 1.25rem;
        }

        .links {
          margin-left: 1rem;
          margin-right: 1rem;
          color: $royal-blue;
          text-align: justify;
          font-family: $fontFamily-regular;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }
    }

    .message_user {
      position: absolute;
      width: rem(28);
      height: rem(28);
      flex-shrink: 0;
      border-radius: rem(4);
      border: rem(1) solid $gray-varient-aeaeae;
      align-self: flex-end;
      background: $input-disabled-dark;
      margin-right: rem(40);

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }

    .default_copy_button_styling {
      position: absolute;
      top: rem(7.5);
      right: rem(6);
      cursor: pointer;
      outline: none;
    }

    .default_icon_styling {
      font-size: 2rem (1);
      opacity: 0.4;
    }

    .icon_styling {
      color: $white;
    }

    .icon_styling:active::before {
      content: "copied!";
      font-size: 14;
      opacity: 0.8;
    }

    .icon_styling::before {
      content: "copy";
      font-size: 14;
      opacity: 0.8;
    }
  }
}
