@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.course-form-container {
    background: $white;
    border-radius: rem(10);
    border: rem(1) solid $box-shadow-bg;
    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
    height: auto;
    padding-bottom: rem(15);

    .course-form-header {
        height: auto;
        padding: rem(16);
        border-top-left-radius: rem(10);
        border-top-right-radius: rem(10);

        .course-form-header-title {
            font-weight: $fontWeight-medium2;
        }

        span {
            padding-left: rem(12);
        }

        .breadcrumb-divider {
            background-color: $evenTableRow;
        }
    }

    .course-form-content {
        margin-top: rem(-5);

        .label {
            font-size: $font-17;
            font-weight: $fontWeight-medium2;
            line-height: rem(19);
            letter-spacing: 0.008em;
        }

        .ant-col-12 {
            max-width: 100%;
        }

        .ant-picker {
            width: 100%;
        }

        .ant-upload-wrapper .ant-upload {
            width: 100%;
        }

        .ant-upload-wrapper .ant-upload span button {
            width: 100%;
            text-align: inherit;
            height: rem(39);
        }
    }

    .add-section-tile {
        border-radius: 6px;
        border: 1px solid var(--neutral-6, $evenTableRow);
        background: var(--neutral-1, $white);
    }

    .section-tile-container {
        margin: auto;
        justify-content: center;
    }
}

.course-form-button-container {
    background: $white;
    border-radius: rem(10);
    border: rem(1) solid $box-shadow-bg;
    box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, 0.15);
    height: auto;

    .form-next-button {
        border-radius: 0%;
        background-color: $primary;
        color: $white;
    }

    .form-clear-button {
        border-radius: 0%;
        background-color: $white;
        color: $primary;
    }
}

.form-add-section-button {
    border-radius: 0%;
    background-color: $white;
    color: $primary;
    margin: 0;
}

.form-add-section-btn-col {
    padding-right: 0 !important;
}

.form-add-section-btn-col-p {
    padding-right: 0 !important;
    margin-top: 3rem;
    margin-bottom: 1rem;
}