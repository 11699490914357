@import "../../styles/function";
@import "../../styles/fonts";
@import "../../styles/colors";
@import "../../Theme/Theme";

.quiz-details-tile {
    border-radius: 6px;
    border: 1px solid var(--neutral-6, $evenTableRow);
    background: var(--neutral-1, $white);
    margin-bottom: rem(15);
    margin-right: rem(12);

    .quiz-details-tile-row {
        padding: 1rem;

        .image-arrow-down {
            width: 15%;
            margin-right: 0.7rem;
            position: relative;
            top: 0.0625rem;
            cursor: pointer;
        }

        .quiz-details-tile-heading {
            .title {
                font-size: rem(16);
                color: $black;
                font-weight: $fontWeight-normal;
            }

            .duration {
                font-size: rem(16);
                color: $dullGrey;
            }

            .ant-col:first-child {
                margin-right: rem(20);
            }

            .ant-col:nth-child(2) {
                padding-right: 0 !important;
            }

            img {
                position: relative;
                top: rem(2);
                height: rem(25);
            }
        }

        .quiz-details-tile-icons {
            display: none;
        }
    }
}

.quiz-details-tile:hover .quiz-details-tile-row .quiz-details-tile-icons {
    display: block;
    transition: opacity 1s;
}