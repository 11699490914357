@import "../../../styles/colors";
@import "../../../styles/function";
@import "../../../styles/fonts";
@import "../../../Theme/Theme";

.message_box-container {
  .response-box-bot {
    border-radius: rem(6);
    border-bottom-left-radius: rem(0);
    background: $white;
    border: $response-box-grey rem(1) solid;
  }

  .response-box-user {
    border-radius: rem(6);
    border-bottom-right-radius: rem(0);
    background: $collapseHeader;
    border: $get-started-btn-bg rem(1) solid;
  }

  .reponse_message-display-bot {
    border: rem(1) solid $st-chat-box !important;
    background: $white !important;
    left: rem(30);
    top: rem(-15);
    color: $st-chat-box;
  }

  .reponse_message-display-user {
    border: rem(1) solid $st-chat-box;
    background: $white !important;
    right: rem(-18);
    bottom: rem(-13);
  }
}
